































































import { Chapter } from '@/@types/global';
import PhotoCard from '@/components/reader/PhotoCard.vue';
import { scrollModule, snackbarModule } from '@/store';
import { db } from '@/utils/firebase';
import { chapters } from '@/utils/utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

@Component({
  components: { PhotoCard },
  beforeRouteLeave: (to: Route, from: Route, next: NavigationGuardNext) => {
    const el = document.getElementById('vcontent') as HTMLElement;
    scrollModule.SET_READER_SCROLL({ scroll: el.scrollTop });
    next();
  }
})
export default class Reader extends Vue {
  contents = [];
  title = '';
  chapterNum = 0;
  chapters = chapters;
  static = false;

  get prevChapter(): Chapter | undefined {
    return this.chapters[this.chapterNum - 1];
  }

  get nextChapter(): Chapter | undefined {
    return this.chapters[this.chapterNum + 1];
  }

  get chapter() {
    return this.chapters[this.chapterNum];
  }

  openOriginalImage(uri: string) {
    window.open(uri, '_blank');
  }

  copyURL(content: { id: string }) {
    const str = window.location.href.split('#')[0] + '#' + content.id;
    navigator.clipboard.writeText(str);
    snackbarModule.SET_TEXT({ text: 'この場所のURLをコピーしました' });
  }

  @Watch('$route.params.chapter')
  async load() {
    this.chapterNum = parseInt(this.$route.params.chapter);
    const chapter = this.chapters[this.chapterNum];
    const ref = db
      .collection('contentSeq')
      .where('page', '>=', chapter.from)
      .where('page', '<=', chapter.to)
      .orderBy('page', 'asc');
    await this.$bind('contents', ref);
  }

  async mounted() {
    await this.load();
    this.$nextTick(() => {
      const el = document.getElementById('vcontent') as HTMLElement;
      const hash = this.$route.hash;
      setTimeout(() => {
        if (hash) {
          const content = this.$refs[hash] as HTMLElement[];
          el.scrollTo(0, content[0].offsetTop);
        } else {
          el.scrollTo(0, scrollModule.readerScroll);
          scrollModule.SET_READER_SCROLL({ scroll: 0 });
        }
      }, 1000);
    });
  }
}
