import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'snackbar' })
export default class ScackbarModule extends VuexModule {

    show = false;
    content = '';

    @Mutation
    SET_TEXT(payload: { text: string; }) {
        this.content = payload.text;
        this.show = true;
    }

    @Mutation
    SET_SNACKBAR(payload: { snackbar: boolean; }) {
        this.show = payload.snackbar;
    }

}
