






















import { ReferenceLink, Segment, User } from '@/@types/global';
import { getSegData, getUserData } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OpenableUserName from './OpenableUserName.vue';
@Component({ components: { OpenableUserName } })
export default class ReferenceLinkCard extends Vue {
  @Prop(Object) link!: ReferenceLink;
  user: User | null = null;
  seg: Segment | null = null;

  get thumbnail() {
    return '/thumbnails/' + this.seg!.index + '.jpg';
  }

  async mounted() {
    this.user = await getUserData(this.link.editedBy);
    this.seg = await getSegData(this.link.segId);
  }
}
