




























import { Reference, ReferenceLink } from '@/@types/global';
import ReferenceLinkCard from '@/components/molecules/ReferenceLinkCard.vue';
import { db, getReferenceData } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
@Component({ components: { ReferenceLinkCard } })
export default class ReferenceListDetail extends Vue {
  reference: Reference | null = null;
  links: ReferenceLink[] = [];
  mediaLabel(ref: Reference) {
    switch (ref.media) {
      case 'book':
        return '書籍';
      case 'inbook':
        return '書籍の一部';
      case 'paper':
        return '学術論文';
      case 'webpage':
        return 'Webページ';
      default:
        return 'その他';
    }
  }

  async mounted() {
    const id = this.$route.params.referenceId;
    this.reference = await getReferenceData(id);
    const ref = db.collection('referenceLinks').where('referenceId', '==', id);
    this.$bind('links', ref);
  }
}
