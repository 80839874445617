



import { Transcription } from '@/@types/global';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class TranscriptionHistoryMedia extends Vue {
  @Prop(Object) anno!: Transcription;

  get text() {
    return this.anno.text.replaceAll('\n', '<br>');
  }

  mounted() {
    this.anno;
  }
}
