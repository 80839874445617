

















import Vue from 'vue';
import Component from 'vue-class-component';
import { dialogModule } from '@/store';
import { getUserData } from '@/utils/firebase';
import { Watch } from 'vue-property-decorator';
import { User } from '@/@types/global';

@Component
export default class LikersDialog extends Vue {
  likers: User[] = [];

  @Watch('open', { deep: true })
  loadLikers() {
    this.likers = [];
    this.likersUids.forEach(async (uid) => {
      const liker = await getUserData(uid);
      this.likers.push(liker);
    });
  }

  get likersUids() {
    return dialogModule.likerUids;
  }

  get open() {
    return dialogModule.isLikersDialogOpen;
  }

  set open(value: boolean) {
    dialogModule.openLikersDialog({ open: value, likerUids: [] });
  }
}
