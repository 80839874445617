





























































import HistoryCard from '@/components/home/HistoryCard.vue';
import { db } from '@/utils/firebase';
import firebase from 'firebase/app';
import MyProfile from '@/components/home/MyProfile.vue';
import Leaderboard from '@/components/home/Leaderboard.vue';
import { Component, Vue } from 'vue-property-decorator';
import DailyProgressPanel from '@/components/home/DailyProgress.vue';
import RandomPhoto from '@/components/home/RandomPhoto.vue';
@Component({
  components: {
    HistoryCard,
    MyProfile,
    Leaderboard,
    DailyProgressPanel,
    RandomPhoto
  }
})
export default class Home extends Vue {
  histories: History[] = [];
  newsList: { date: firebase.firestore.Timestamp; text: string }[] = [];
  bindNewsList() {
    this.$bind(
      'newsList',
      db.collection('news').orderBy('timestamp', 'desc').limit(5)
    );
  }

  mounted() {
    const ref = db
      .collection('histories')
      .orderBy('timestamp', 'desc')
      .limit(50);
    this.$bind('histories', ref);
    this.bindNewsList();
  }
}
