




















































































import algoliasearch from 'algoliasearch/lite';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import 'instantsearch.css/themes/satellite-min.css';
import { PersonInfo } from '@/@types/global';
import { viewerModule } from '@/store';

@Component
export default class PersonSearchDialog extends Vue {
  @Prop(Boolean) value = false;
  @Prop(String) uri!: string;
  query = '';
  selectedPerson: PersonInfo | null = null;
  showHits = false;

  eiichi: PersonInfo = {
    description:
      '渋沢 栄一（しぶさわ えいいち、天保11年2月13日（1840年3月16日） - 昭和6年（1931年）11月11日）は、江戸時代末期（幕末）から大正初期にかけての日本の武士（幕臣）、官僚、実業家。第一国立銀行や東京証券取引所などといった多種多様な企業の設立・経営に関わり、日本資本主義の父といわれる。理化学研究所の創設者でもある。正二位勲一等子爵。雅号に青淵（せいえん）。',
    image:
      'http://commons.wikimedia.org/wiki/Special:FilePath/Eiichi_Shibusawa.jpg?width=300',
    name: '渋沢栄一',
    objectID: 'https://shibusawa-dlab.github.io/lab1/api/chname/渋沢栄一'
  };

  get open() {
    return this.value;
  }

  set open(value: boolean) {
    this.$emit('cancel');
  }

  get selectedImageURI() {
    return viewerModule.selectedImageURI;
  }

  hideHits() {
    setTimeout(() => (this.showHits = false), 300);
  }

  selectPerson(person: PersonInfo) {
    this.selectedPerson = person;
    this.showHits = false;
  }

  selectEiichi() {
    this.selectedPerson = this.eiichi;
    this.showHits = false;
  }

  searchClient = algoliasearch(
    '7ZRRU66ATT',
    'b7771a540f82219e0a99982aadabe3cf'
  );

  cancel() {
    this.$emit('cancel');
    this.query = '';
    this.selectedPerson = null;
    viewerModule.SET_SELECTION({ selection: null });
  }

  addPerson() {
    this.$emit('addPerson', this.selectedPerson);
    this.selectedPerson = null;
  }

  request() {
    this.$emit('requestRegistration', this.query);
  }
}
