























import { EmbeddedTranscription, Report } from '@/@types/global';
import {
  annotationModule,
  authModule,
  snackbarModule,
  viewerModule
} from '@/store';
import { db } from '@/utils/firebase';
import { IAnnotation } from '@recogito/annotorious';
import firebase from 'firebase/app';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EmbeddedTranscriptionDialog extends Vue {
  @Prop(Boolean) value = false;
  text = '';
  comment = '';

  get currentUser() {
    return authModule.currentUser;
  }

  get selection() {
    return viewerModule.selection;
  }

  get selectedImageURI() {
    return viewerModule.selectedImageURI;
  }

  get open() {
    return this.value;
  }

  set open(value: boolean) {
    this.$emit('cancel');
  }

  cancel() {
    this.$emit('cancel');
    this.text = '';
  }

  get selectionToAnnotorious(): IAnnotation | null {
    if (!this.selection) return null;
    return {
      '@context': 'http://www.w3.org/ns/anno.jsonld',
      type: 'Annotation',
      target: this.selection!.target,
      body: [
        {
          value: this.text,
          purpose: 'commenting',
          type: 'TextualBody'
        }
      ],
      id: this.selectedImageURI,
      imageId: this.selectedImageURI
    };
  }

  async submit() {
    if (!this.currentUser) return;
    const embedded: EmbeddedTranscription = {
      segId: annotationModule.seg.id,
      imageURI: viewerModule.selectedImageURI,
      annotorious: this.selectionToAnnotorious as IAnnotation,
      editedBy: this.currentUser.uid,
      comment: this.comment,
      text: this.text,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    await db.collection('embeddedTranscriptions').add(embedded);
    this.text = '';
    this.$emit('submit');
    snackbarModule.SET_TEXT({ text: '埋め込み翻刻文を保存しました。' });
    annotationModule.setWatch({ value: true });
  }
}
