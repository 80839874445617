

















import { Segment } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PhotoList extends Vue {
  segs: Segment[] = [];

  @Watch('$route.params.index')
  bind() {
    const index = parseInt(this.$route.params.index);
    const ref = db
      .collection('segs')
      .where('collection', '==', index)
      .orderBy('index', 'asc');
    this.$bind('segs', ref);
  }

  thumbnail(seg: Segment) {
    return '/thumbnails/' + seg.index + '.jpg';
  }

  mounted() {
    this.bind();
  }
}
