































import { authModule } from '@/store';
import { NotificationMsg, User } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import NotificationItem from './NotificationItem.vue';

@Component({
  components: { NotificationItem },
  computed: mapState({ currentUser: (state: any) => state.currentUser })
})
export default class NotificationMenu extends Vue {
  show = false;
  notifications: NotificationMsg[] = [];

  get currentUser() {
    return authModule.currentUser;
  }

  get uncheckedCount() {
    return this.notifications.filter((notif) => notif.state === 'unchecked')
      .length;
  }

  bind() {
    if (!this.currentUser) return;
    const ref = db
      .collection('notifications')
      .where('uid', '==', this.currentUser.uid)
      .orderBy('timestamp', 'desc')
      .limit(30);
    this.$bind('notifications', ref);
  }

  @Watch('show')
  markChecked() {
    if (!this.show) return;
    this.notifications.forEach((notif) => {
      if (notif.state === 'unchecked') {
        db.collection('notifications')
          .doc(notif.id)
          .update({ state: 'checked' });
      }
    });
  }

  created() {
    this.bind();
  }
}
