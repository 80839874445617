









































import { Component, Vue } from 'vue-property-decorator';
import { authModule, dialogModule } from '@/store';
import SignInDialog from '@/components/SignInDialog.vue';
import NotificationMenu from './navigation/NotificationMenu.vue';

@Component({
  components: {
    SignInDialog,
    NotificationMenu
  }
})
export default class UserMenu extends Vue {
  get currentUser() {
    return authModule.currentUser;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  openUserProfileDialog() {
    dialogModule.OPEN_PROFILE_EDIT_DIALOG({ open: true });
  }

  closeUserProfileDialog() {
    dialogModule.OPEN_PROFILE_EDIT_DIALOG({ open: false });
  }

  openSignInDialog() {
    dialogModule.openSignInDialog({ open: true });
  }

  closeSignInDialog() {
    dialogModule.openSignInDialog({ open: false });
  }

  signOut() {
    authModule.signOut();
  }
}
