



















import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { db, getSegData, getUserData } from '@/utils/firebase';
import { NotificationMsg, Segment } from '@/@types/global';

@Component
export default class NotificationItem extends Vue {
  @Prop(Object) notif!: NotificationMsg;

  user = {};
  seg: Segment | null = null;

  async created() {
    this.user = await getUserData(this.notif.from);
    this.seg = await getSegData(this.notif.data.segId);
  }

  jump() {
    db.collection('notifications').doc(this.notif.id).update({ state: 'read' });
    this.$router.push({
      name: 'Info',
      params: {
        id: this.notif.data.segId
      }
    });
  }
}
