



import { Component, Vue } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Location, Segment } from '@/@types/global';
import { getFBDoc } from '@/utils/utils';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { db, getUserData } from '@/utils/firebase';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
@Component
export default class PhotoMap extends Vue {
  geos: Location[] = [];
  map!: L.Map;
  screenshooter!: SimpleMapScreenshoter;

  async createMarkers(geo: Location) {
    if (!geo.hasGeoInfo) return;
    const marker = L.marker([geo.lat, geo.lng]).addTo(this.map);
    const seg = await getFBDoc<Segment>('segs', geo.id!);
    const user = await getUserData(geo.editedBy);
    const template = `
        <img style="width: 300px; max-height: 500px;" src="/thumbnails/${seg.index}.jpg"/><br>
        <a href="/annotate/photo/${seg.id}/geo">${seg.index} ${seg.metadata[4]}</a>
        <p><img height="20" style="margin-right: 3px;" src="${user.photoURL}"><b>${user.displayName}</b>さんのコメント：</p>
        <p>${geo.comment}</p>
    `;
    marker.bindPopup(template);
  }

  async bind() {
    const ref = db.collection('geo');
    await this.$bind('geos', ref);
  }

  async mounted() {
    this.map = L.map('map', {
      center: L.latLng(35.6825, 139.752778),
      zoom: 9
    }).addLayer(
      L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', {
        attribution:
          "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
      })
    );
    this.screenshooter = new SimpleMapScreenshoter().addTo(this.map);
    await this.bind();
    this.geos.forEach((g) => this.createMarkers(g));
  }
}
