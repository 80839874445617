









import { snackbarModule } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({ name: 'snackbar' })
export default class Snackbar extends Vue {
    timeout = 2000;

    get text() {
        return snackbarModule.content;
    }

    get snackbar() {
        return snackbarModule.show;
    }

    set snackbar(snackbar: boolean) {
        snackbarModule.SET_SNACKBAR({ snackbar });
    }
}
