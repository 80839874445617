
























































































































import { authModule } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({ name: 'sidebar' })
export default class Sidebar extends Vue {
  @Prop(Boolean) value!: boolean;

  get currentUser() {
    return authModule.currentUser;
  }

  get drawer() {
    return this.value;
  }

  set drawer(val: boolean) {
    this.$emit('toggle');
  }

  links = [
    {
      label: '渋沢栄一フォトグラフ',
      uri: '/'
    },
    {
      label: '『渋沢栄一伝記資料』別巻第10',
      uri: '/supvol10/'
    },
    {
      label: '渋沢栄一記念財団',
      uri: 'https://www.shibusawa.or.jp/index.html'
    },
    {
      label: 'デジタル版『渋沢栄一伝記資料』',
      uri: 'https://eiichi.shibusawa.or.jp/denkishiryo/digital/main/index.php'
    },
    {
      label: '渋沢栄一ダイアリー',
      uri: 'https://shibusawa-dlab.github.io/app1/'
    },
    {
      label: '総合資料学の創成',
      uri: 'https://www.metaresource.jp/'
    }
  ];

  collection = [
    {
      label: '前期',
      count: 0,
      index: 0
    },
    {
      label: '中期1',
      count: 0,
      index: 1
    },
    {
      label: '中期2',
      count: 0,
      index: 2
    },
    {
      label: '後期1',
      count: 0,
      index: 3
    },
    {
      label: '後期2',
      count: 0,
      index: 4
    },
    {
      label: '諸影',
      count: 0,
      index: 5
    },
    {
      label: '邸宅',
      count: 0,
      index: 6
    },
    {
      label: '永眠と記念事業',
      count: 0,
      index: 7
    }
  ];
}
