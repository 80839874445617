import { User } from '@/@types/global';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'dialog' })
export default class DialogModule extends VuexModule {
	completeAnnoDialog = false;
	tutorialDialog = false;
	signInDialog = false;
	likersDialog = false;
	likerUids: string[] = [];
	profileEditDialog = false;
	profileUid = "";
	imageViewerDialog = false;
	imageViewerURI: string | null = null;
	messageDialog = false;
	messageDialogTitle = "";
	messageDialogMsg = "";
	profileDisplayDialog = false;
	profileDisplayDialogUser: User | null = null;

	get isSignInDialogOpen() {
		return this.signInDialog;
	}

	get isLikersDialogOpen() {
		return this.likersDialog;
	}

	get isTutotialDialogOpen() {
		return this.tutorialDialog;
	}

	@Mutation
	OPEN_MESSAGE_DIALOG(payload: { value: boolean, message?: string; title?: string; }) {
		this.messageDialog = payload.value;
		this.messageDialogMsg = payload.message ? payload.message : "";
		this.messageDialogTitle = payload.title ? payload.title : "";
	}


	@Mutation
	OPEN_IMAGE_VIEWER_DIALOG(payload: { value: boolean, uri?: string; }) {
		this.imageViewerDialog = payload.value;
		this.imageViewerURI = payload.uri ? payload.uri : null;
	}


	@Mutation
	TOGGLE_COMPLETE_ANNO_DIALOG(payload: { value: boolean; }) {
		this.completeAnnoDialog = payload.value;
	}

	@Mutation
	OPEN_TUTORIAL_DIALOG(payload: { open: boolean; }) {
		this.tutorialDialog = payload.open;
	}

	@Action({ commit: 'TOGGLE_TUTORIAL_DIALOG' })
	openTutorialDialog(payload: { open: boolean; }) {
		return payload;
	}

	@Mutation
	OPEN_SIGN_IN_DIALOG(payload: { open: boolean; }) {
		this.signInDialog = payload.open;
	}

	@Action({ commit: 'OPEN_SIGN_IN_DIALOG' })
	openSignInDialog(payload: { open: boolean; }) {
		return payload;
	}

	@Mutation
	OPEN_LIKERS_DIALOG(payload: { open: boolean; likerUids?: string[]; }) {
		this.likersDialog = payload.open;
		if (payload.likerUids) this.likerUids = payload.likerUids;
	}

	@Action({ commit: 'OPEN_LIKERS_DIALOG' })
	openLikersDialog(payload: { open: boolean; likerUids?: string[]; }) {
		return payload;
	}

	@Mutation
	OPEN_PROFILE_EDIT_DIALOG(payload: { open: boolean; }) {
		this.profileEditDialog = payload.open;
	}

	@Mutation
	OPEN_PROFILE_DISPLAY_DIALOG(payload: { open: boolean; user?: User; }) {
		this.profileDisplayDialog = payload.open;
		if (payload.user) {
			this.profileDisplayDialogUser = payload.user;
		}
	}
}
