



























import { Post } from '@/@types/global';
import { annotationModule, authModule, snackbarModule } from '@/store';
import { db } from '@/utils/firebase';
import firebase from 'firebase/app';
import { Component, Vue, Watch } from 'vue-property-decorator';
import PostListItem from './PostListItem.vue';
@Component({ components: { PostListItem } })
export default class DiscussionTab extends Vue {
  comment = '';
  deleteDialog = false;
  posts: Post[] = [];
  deletionCandidate: Post | null = null;

  get currentUser() {
    return authModule.currentUser;
  }

  openDeleteDialog(post: Post) {
    this.deletionCandidate = post;
    this.deleteDialog = true;
  }

  async deletePost() {
    if (this.deletionCandidate && this.currentUser) {
      await db.collection('posts').doc(this.deletionCandidate.id).delete();
      snackbarModule.SET_TEXT({ text: '投稿を削除しました' });
    }
    this.deleteDialog = false;
  }

  async addPost() {
    const currentUser = authModule.currentUser;
    if (!currentUser) return;
    const post: Post = {
      editedBy: currentUser.uid,
      segId: this.$route.params.id,
      comment: this.comment,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    await db.collection('posts').add(post);
    this.comment = '';
    snackbarModule.SET_TEXT({ text: 'コメントを投稿しました' });
    annotationModule.setWatch({ value: true });
  }

  @Watch('$route.params.id')
  async bind() {
    const ref = db
      .collection('posts')
      .where('segId', '==', this.$route.params.id)
      .orderBy('timestamp', 'desc')
      .limit(100);
    await this.$bind('posts', ref);
  }

  mounted() {
    this.bind();
  }
}
