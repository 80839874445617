













import { Component, Prop, Vue } from 'vue-property-decorator';
import GeoHistoryMedia from '../history/GeoHistoryMedia.vue';
import PersonAnnotationHistoryMedia from '../history/PersonAnnotationHistoryMedia.vue';
import TranscriptionHistoryMedia from '../history/TranscriptionHistoryMedia.vue';
import EmbeddedTranscriptionHistoryMedia from '../history/EmbeddedTranscriptionHistoryMedia.vue';
import PhotoUploadHistoryMedia from '../history/PhotoUploadHistoryMedia.vue';
import ReferenceHistoryMedia from '../history/ReferenceHistoryMedia.vue';
import { History } from '@/@types/global';
@Component({
  components: {
    GeoHistoryMedia,
    PersonAnnotationHistoryMedia,
    TranscriptionHistoryMedia,
    EmbeddedTranscriptionHistoryMedia,
    PhotoUploadHistoryMedia,
    ReferenceHistoryMedia
  }
})
export default class HistoryMedia extends Vue {
  @Prop(Object) history!: History;
  @Prop({ default: 300 }) height!: number;

  jump() {
    const params = { id: this.history.segId };
    switch (this.history.type) {
      case 'personAnnotation':
        this.$router.push({ name: 'People', params });
        break;
      case 'geo':
        this.$router.push({ name: 'Geo', params });
        break;
      case 'transcription':
        this.$router.push({ name: 'Transcription', params });
        break;
      case 'embeddedTranscription':
        this.$router.push({ name: 'Transcription', params });
        break;
      case 'photoUpload':
        this.$router.push({ name: 'PhotoUpload', params });
        break;
      case 'reference':
        this.$router.push({ name: 'References', params });
        break;
      case 'post':
        this.$router.push({ name: 'Discussion', params });
        break;
    }
  }
}
