import { IAnnotation } from '@recogito/annotorious';
import { ISelection } from '@recogito/annotorious';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'viewer' })
export default class ViewerModule extends VuexModule {

    selection: ISelection | null = {
        type: 'Selection',
        target: {
            selector: { value: "" },
            source: ""
        }
    };
    annotorious: IAnnotation[] = [];

    selectedAnnotorious: IAnnotation | null = {
        '@context': 'http://www.w3.org/ns/anno.jsonld',
        type: 'Annotation',
        target: this.selection!.target,
        body: [
            {
                value: '',
                purpose: 'commenting',
                type: 'TextualBody'
            }
        ],
        id: this.selectedImageURI,
        imageId: this.selectedImageURI
    };


    get selectedImageURI() {
        if (!this.selection) return "";
        const src = this.selection!.target.source;
        const selector = this.selection!.target.selector.value;
        const m1 = src.match(/([0-9]+),([0-9]+),([0-9]+),([0-9]+)/);
        const m2 = selector.match(/([0-9]+)\.[0-9]+,([0-9]+)\.[0-9]+,([0-9]+)\.[0-9]+,([0-9]+)\.[0-9]+/);
        if (!m1 || !m2) return "";
        const x = parseInt(m1![1]) + parseInt(m2![1]);
        const y = parseInt(m1![2]) + parseInt(m2![2]);
        const w = m2![3];
        const h = m2![4];
        const box = `${x},${y},${w},${h}`;
        return src.replace(/[0-9]+,[0-9]+,[0-9]+,[0-9]+/, box);
    }

    get selectionToAnnotorious(): IAnnotation | null {
        if (!this.selection) return null;
        return {
            '@context': 'http://www.w3.org/ns/anno.jsonld',
            type: 'Annotation',
            target: this.selection!.target,
            body: [
                {
                    value: "",
                    purpose: 'commenting',
                    type: 'TextualBody'
                }
            ],
            id: this.selectedImageURI,
            imageId: this.selectedImageURI
        };
    }

    @Mutation
    SET_SELECTION(payload: { selection: ISelection | null; }) {
        this.selection = payload.selection;
    }

    @Mutation
    SET_ANNOTORIOUS(payload: { annotorious: IAnnotation[]; }) {
        this.annotorious = payload.annotorious;
    }

    @Mutation
    SET_SELECTED_ANNOTORIOUS(payload: { annotorious: IAnnotation; }) {
        this.selectedAnnotorious = payload.annotorious;
    }
}
// xywh=pixel:296.8138122558594,110.59866333007812,178.26296997070312,231.30001831054688
// https://eiichi.shibusawa.or.jp/denkishiryo-bekkan/api/images/iiif/1702/2246,517,613,1405/max/0/default.jpg