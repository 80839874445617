



































import Vue from 'vue';
import Component from 'vue-class-component';
import { dialogModule } from '@/store';

@Component
export default class ProfileDisplayDialog extends Vue {
  get user() {
    return dialogModule.profileDisplayDialogUser;
  }

  get open() {
    return dialogModule.profileDisplayDialog;
  }

  set open(value: boolean) {
    dialogModule.OPEN_PROFILE_DISPLAY_DIALOG({ open: value });
  }
}
