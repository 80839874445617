






































import { History, PhotoUpload, User } from '@/@types/global';
import { authModule, dialogModule } from '@/store';
import { getHistoryByAnnoId, getUserData } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OpenableUserName from '../molecules/OpenableUserName.vue';
import LikeBtn from '../molecules/LikeBtn.vue';
import LikeCounter from '../molecules/LikeCounter.vue';
@Component({ components: { OpenableUserName, LikeBtn, LikeCounter } })
export default class PhotoUploadCard extends Vue {
  @Prop(Object) upload!: PhotoUpload;
  user: User | null = null;
  history: History | null = null;
  imageError = false;

  openImage() {
    dialogModule.OPEN_IMAGE_VIEWER_DIALOG({
      value: true,
      uri: this.upload.photoURL
    });
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isMyUpload() {
    return this.upload.editedBy == authModule.currentUser?.uid;
  }

  get image() {
    if (this.imageError) {
      return this.upload.photoURL;
    } else {
      return this.thumbnail;
    }
  }

  get thumbnail() {
    const uri = this.upload.photoURL;
    const regex =
      /(https:\/\/firebasestorage.googleapis.com\/v0\/b\/shibusawa-8a04b.appspot.com\/o\/images%2F)(.+)\.(.+?)\?alt=media&token=.+/;
    const m = uri.match(regex);
    const base = m![1];
    const basename = m![2];
    const extension = m![3];
    return (
      base + 'resized%2F' + basename + '_300x300.' + extension + '?alt=media'
    );
  }

  onImgError() {
    this.imageError = true;
  }

  async mounted() {
    const uid = this.upload.editedBy;
    this.user = await getUserData(uid);
    this.history = await getHistoryByAnnoId(this.upload.id!);
  }
}
