





























import { Reference, ReferenceLink } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
import ReferenceCard from '@/components/annotate/ReferenceCard.vue';
@Component({ components: { ReferenceCard } })
export default class ReferenceList extends Vue {
  references: { [id: string]: { count: number; data: Reference } } = {};

  mediaLabel(ref: Reference) {
    switch (ref.media) {
      case 'book':
        return '書籍';
      case 'inbook':
        return '書籍の一部';
      case 'paper':
        return '学術論文';
      case 'webpage':
        return 'Webページ';
      default:
        return 'その他';
    }
  }

  async setup() {
    const references: { [id: string]: { count: number; data: Reference } } = {};
    const refs = await db.collection('references').get();
    refs.forEach((doc) => {
      const reference = doc.data() as Reference;
      references[doc.id] = { count: 0, data: reference };
    });
    const links = await db.collection('referenceLinks').get();
    links.forEach((doc) => {
      const link = doc.data() as ReferenceLink;
      references[link.referenceId].count++;
    });
    this.references = references;
  }

  async mounted() {
    await this.setup();
  }
}
