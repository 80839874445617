



























import { DailyProgress } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class DailyProgressPanel extends Vue {
  progress: DailyProgress | null = null;

  async mounted() {
    const col = await db
      .collection('dailyProgress')
      .orderBy('timestamp', 'desc')
      .limit(1)
      .get();
    this.progress = col.docs[0].data() as DailyProgress;
  }
}
