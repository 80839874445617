










import { EmbeddedTranscription } from '@/@types/global';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class EmbeddedTranscriptionHistoryMedia extends Vue {
  @Prop(Object) anno!: EmbeddedTranscription;

  mounted() {
    this.anno;
  }
}
