



import { User } from '@/@types/global';
import { dialogModule } from '@/store';

import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class OpenableUserName extends Vue {
  @Prop(Object) user!: User;

  open() {
    dialogModule.OPEN_PROFILE_DISPLAY_DIALOG({
      open: true,
      user: this.user
    });
  }
}
