














































import { Component, Vue, Watch } from 'vue-property-decorator';
import algoliasearch from 'algoliasearch';
import 'instantsearch.css/themes/satellite-min.css';
import { getChapterOfPage } from '@/utils/utils';
@Component
export default class Search extends Vue {
  query = '';
  page = 1;
  result: any | null = null;
  hits = [];
  total = 0;
  totalPages = 1;
  client = algoliasearch('7ZRRU66ATT', 'b7771a540f82219e0a99982aadabe3cf');
  segIndex = this.client.initIndex('segs');
  textIndex = this.client.initIndex('texts');
  target: 'segs' | 'texts' = 'segs';

  mounted() {
    this.query = this.$route.query.q as string;
    this.page = parseInt(this.$route.query.page as string);
    this.target = (this.$route.query.target || 'segs') as 'segs' | 'texts';
    this.search();
  }

  chapterNumOfPage(page: number) {
    const chap = getChapterOfPage(page);
    if (chap) {
      return chap.index;
    } else {
      return 0;
    }
  }

  updateQuery() {
    this.$router
      .replace({
        name: 'Search',
        query: { q: this.query, page: '1', target: this.target }
      })
      .catch();
  }

  updatePage() {
    this.$router
      .replace({
        name: 'Search',
        query: { q: this.query, page: this.page + '', target: this.target }
      })
      .catch();
  }

  @Watch('$route.query', { deep: true })
  async search() {
    this.query = this.$route.query.q as string;
    this.page = parseInt(this.$route.query.page as string);
    if (!this.query || !this.page) return;
    if (this.target == 'segs') {
      this.result = await this.segIndex.search(this.query, {
        hitsPerPage: 10,
        page: this.page - 1,
        attributesToSnippet: ['label:80']
      });
    } else {
      this.result = await this.textIndex.search(this.query, {
        hitsPerPage: 10,
        page: this.page - 1,
        attributesToSnippet: ['content:80']
      });
    }
    this.hits = this.result.hits;
    this.totalPages = this.result.nbPages;
    this.total = this.result.nbHits;
  }
}
