








import { PersonAnnotation } from '@/@types/global';
import PersonCard from '@/components/molecules/PersonCard.vue';
import { db } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
@Component({ components: { PersonCard } })
export default class People extends Vue {
  people: { [id: string]: PersonAnnotation[] } = {};

  get count() {
    return Object.keys(this.people).length;
  }

  async setup() {
    const annos: PersonAnnotation[] = [];
    const people: { [id: string]: PersonAnnotation[] } = {};
    const col = await db.collection('personAnnotations').get();
    col.forEach((doc) => {
      annos.push(doc.data() as PersonAnnotation);
    });
    annos.forEach((anno) => {
      if (!anno.isRegistrationRequest) {
        people[anno.personId] = people[anno.personId] || [];
        people[anno.personId].push(anno);
      }
    });
    this.people = people;
  }

  async mounted() {
    await this.setup();
  }
}
