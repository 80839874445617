



















import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import UserMenu from '@/components/UserMenu.vue';
import Snackbar from '@/components/molecules/Snackbar.vue';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import DialogContainer from '@/components/dialogs/DialogContainer.vue';

@Component({
  components: {
    Sidebar,
    UserMenu,
    Snackbar,
    MessageDialog,
    DialogContainer
  }
})
export default class extends Vue {
  drawer = true;
  jumpToHome() {
    this.$router.push({ name: 'Home' });
  }
}
