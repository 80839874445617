





















import { authModule, dialogModule } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class MyProfile extends Vue {
  get currentUser() {
    return authModule.currentUser;
  }

  editProfile() {
    dialogModule.OPEN_PROFILE_EDIT_DIALOG({ open: true });
  }
}
