import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import { History, Reference, Segment, User } from '@/@types/global';
import { authModule } from '@/store';

const firebaseConfig = {
    apiKey: "AIzaSyAUUypjWCgUiQTPZV-wfyVGKXKSezVOsvg",
    authDomain: "shibusawa-8a04b.firebaseapp.com",
    projectId: "shibusawa-8a04b",
    storageBucket: "shibusawa-8a04b.appspot.com",
    messagingSenderId: "293215298825",
    appId: "1:293215298825:web:5a94e0ee7d3a81261cdfd8",
    measurementId: "G-VS6HY1PP62"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const functions = firebase.functions();


async function createUserData(uid: string, displayName: string, photoURL: string) {
    await db
        .collection('users')
        .doc(uid)
        .set({ uid, displayName, photoURL });
}

async function isRegisteredUser(uid: string) {
    const user = await db.collection('users').doc(uid).get();
    return user.exists;
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.auth().onAuthStateChanged(async (userInfo: firebase.User | null) => {
    if (userInfo) {
        if (!await isRegisteredUser(userInfo.uid)) {
            const user: User = {
                displayName: userInfo.displayName || '',
                photoURL: userInfo.photoURL || '',
                uid: userInfo.uid,
                profile: '',
                website: '',
                exp: 0,
                likeCount: 0,
                createdAt: firebase.firestore.Timestamp.now(),
            };
            await createUserData(userInfo.uid, userInfo.displayName || '', userInfo.photoURL || '');
        }
        authModule.bindUser({ uid: userInfo.uid });
    } else {
        authModule.signOut();
    }
});
export const auth = firebase.auth;


const userCache: { [uid: string]: User; } = {};
export async function getUserData(uid: string): Promise<User> {
    if (userCache[uid]) {
        return await userCache[uid];
    } else {
        const doc = await db.collection('users').doc(uid).get();
        userCache[uid] = doc.data() as User;
        return doc.data() as User;
    }
}

const segCache: { [id: string]: Segment; } = {};
export async function getSegData(id: string): Promise<Segment> {
    if (segCache[id]) {
        return await segCache[id];
    } else {
        const doc = await db.collection('segs').doc(id).get();
        segCache[id] = doc.data() as Segment;
        return doc.data() as Segment;
    }
}

const referenceCache: { [id: string]: Reference; } = {};
export async function getReferenceData(id: string): Promise<Reference> {
    if (referenceCache[id]) {
        return await referenceCache[id];
    } else {
        const doc = await db.collection('references').doc(id).get();
        referenceCache[id] = doc.data() as Reference;
        return doc.data() as Reference;
    }
}

export async function getHistoryByAnnoId(annoId: string): Promise<History | null> {
    const col = await db.collection('histories').where('annoId', '==', annoId).get();
    if (col.empty) {
        return null;
    } else {
        const h = col.docs[0].data() as History;
        h.id = col.docs[0].id;
        return h;
    }
}