import { User } from '@/@types/global';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'scroll' })
export default class ScrollModule extends VuexModule {
    readerScroll = 0;

    @Mutation
    SET_READER_SCROLL(payload: { scroll: number; }) {
        this.readerScroll = payload.scroll;
    }
}
