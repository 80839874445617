import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { db, auth } from '@/utils/firebase';
import { User } from '@/@types/global';
import { bindFirebaseRef } from '@/utils/bindFirebaseRef';


@Module({ namespaced: true, name: 'auth' })
export default class AuthModule extends VuexModule {

  currentUser: User | null = null;
  isAdmin = false;

  @Mutation SET_CURRENT_USER(user: User) {
    this.currentUser = user;
  }

  @Mutation SET_ADMIN(payload: { isAdmin: boolean; }) {
    this.isAdmin = payload.isAdmin;
  }

  get currentUserUid(): string | null {
    if (!this.currentUser) return null;
    return this.currentUser.uid;
  }

  @Action
  async bindUser(payload: { uid: string; }) {
    const userRef = db.collection("users").doc(payload.uid);
    const doc = await db.collection("admins").doc(payload.uid).get();
    const isAdmin = doc.exists;
    this.SET_ADMIN({ isAdmin });
    await bindFirebaseRef(this.context, 'currentUser', userRef);
  }

  @Action({ commit: 'SET_CURRENT_USER' })
  async signOut() {
    await auth().signOut();
    return null;
  }

  @Action
  public async signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    await auth().signInWithPopup(provider);
  }

  @Action
  public async signInWithTwitter() {
    const provider = new auth.TwitterAuthProvider();
    await auth().signInWithPopup(provider);
  }

  @Action
  public async signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    await auth().signInWithPopup(provider);
  }

}