





























import { PersonAnnotation, PersonInfo } from '@/@types/global';
import { db, getSegData } from '@/utils/firebase';
import algoliasearch from 'algoliasearch';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class PersonAnnotationList extends Vue {
  person: PersonInfo | null = null;
  annos: PersonAnnotation[] = [];
  segLabels: string[] = [];
  client = algoliasearch('7ZRRU66ATT', 'b7771a540f82219e0a99982aadabe3cf');
  index = this.client.initIndex('person');

  async mounted() {
    const annos: PersonAnnotation[] = [];
    const personId = this.$route.params.personId;
    this.person = await this.index.getObject(personId);
    const ref = db
      .collection('personAnnotations')
      .where('personId', '==', personId);
    const col = await ref.get();
    col.forEach((doc) => {
      const anno = doc.data() as PersonAnnotation;
      annos.push(anno);
    });
    this.annos = annos;
    const labels: string[] = [];
    this.annos.forEach(async (anno) => {
      const seg = await getSegData(anno.segId);
      labels.push(`${seg.index} ${seg.metadata[4]}`);
    });
    this.segLabels = labels;
  }
}
