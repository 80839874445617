














import { PersonAnnotation, PersonInfo } from '@/@types/global';
import algoliasearch from 'algoliasearch';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class PersonCard extends Vue {
  @Prop(String) personId!: string;
  @Prop(Array) annos!: PersonAnnotation[];
  client = algoliasearch('7ZRRU66ATT', 'b7771a540f82219e0a99982aadabe3cf');
  index = this.client.initIndex('person');
  person: PersonInfo | null = null;

  async mounted() {
    this.person = await this.index.getObject(this.personId);
  }
}
