






import { dialogModule } from '@/store';
import { LikeEntry } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class LikeCounter extends Vue {
  @Prop(String) collection!: string;
  @Prop(String) id!: string;
  likeEntry: LikeEntry = { collection: '', docId: '', likers: {} };

  get count() {
    if (!this.likeEntry || !this.likeEntry.likers) return 0;
    return this.likers.length;
  }

  get ref() {
    const likeEntryId = `${this.collection}-${this.id}`;
    return db.collection('likeEntries').doc(likeEntryId);
  }

  get likers(): string[] {
    const likers = this.likeEntry.likers;
    return Object.keys(likers).filter((k) => likers[k]);
  }

  openLikersDialog() {
    dialogModule.openLikersDialog({
      open: true,
      likerUids: Object.keys(this.likeEntry.likers)
    });
  }

  mounted() {
    this.$bind('likeEntry', this.ref);
  }
}
