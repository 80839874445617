















































































































import {
  PersonAnnotation,
  PersonInfo,
  Report,
  Segment,
  User
} from '@/@types/global';
import OpenableUserName from '@/components/molecules/OpenableUserName.vue';
import { authModule, snackbarModule } from '@/store';
import { db, functions, getSegData, getUserData } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';

@Component({
  components: { OpenableUserName },
  beforeRouteEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
    next();
    //    if (authModule.isAdmin) {
    //  }
  }
})
export default class AdminView extends Vue {
  requests: PersonAnnotation[] = [];
  selectedIndex = 0;

  reportedBy: User | null = null;
  seg: Segment | null = null;

  query = '';
  selectedPerson: PersonInfo | null = null;
  showHits = false;
  comment = '';

  get selected() {
    return this.requests[this.selectedIndex];
  }

  get currentUser() {
    return authModule.currentUser;
  }

  async onSelectionChange() {
    this.reportedBy = await getUserData(this.selected.editedBy);
    this.seg = await getSegData(this.selected.segId);
  }

  selectPerson(person: PersonInfo) {
    this.selectedPerson = person;
    this.showHits = false;
  }

  searchClient = algoliasearch(
    '7ZRRU66ATT',
    'b7771a540f82219e0a99982aadabe3cf'
  );

  hideHits() {
    setTimeout(() => (this.showHits = false), 300);
  }

  async confirmRegistration() {
    if (
      window.confirm(
        'この人物に設定してよろしいですか？この操作は取り消せません'
      )
    ) {
      await this.resolveRegistrationRequest();
    }
  }

  async resolveRegistrationRequest() {
    if (!this.selectPerson) return;
    const resolveRegistrationResuest = functions.httpsCallable(
      'resolveRegistrationRequest'
    );
    try {
      await resolveRegistrationResuest({
        annotationId: this.selected.id,
        personId: this.selectedPerson?.objectID
      });
    } catch (error) {
      return console.log(error);
    }
    snackbarModule.SET_TEXT({ text: '設定が完了しました' });
  }

  mounted() {
    const ref = db
      .collection('personAnnotations')
      .where('isRegistrationRequest', '==', true)
      .orderBy('timestamp', 'desc');
    this.$bind('requests', ref);
  }
}
