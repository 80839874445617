



























































































import { EmbeddedTranscription, Transcription } from '@/@types/global';
import {
  annotationModule,
  authModule,
  snackbarModule,
  viewerModule
} from '@/store';
import { db } from '@/utils/firebase';
import { Component, Vue, Watch } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/firestore';
import EmbeddedTranscriptionDialog from './EmbeddedTranscriptionDialog.vue';
@Component({ components: { EmbeddedTranscriptionDialog } })
export default class TranscriptionTab extends Vue {
  embeds: EmbeddedTranscription[] = [];
  selectedEmbed: EmbeddedTranscription | null = null;
  comment = '';
  text = '';
  dialog = false;
  embeddedDialog = false;
  deleteEmbeddedDialog = false;

  get id() {
    return this.$route.params.id;
  }

  @Watch('id')
  async setText() {
    const doc = await db.collection('transcriptions').doc(this.id).get();
    if (doc.exists) {
      const trsc = doc.data();
      this.text = trsc!.text;
    }
  }

  get selection() {
    return viewerModule.selection;
  }

  get selectedImageURI() {
    return viewerModule.selectedImageURI;
  }

  get currentUser() {
    return authModule.currentUser;
  }

  get segStatus() {
    return annotationModule.segStatus;
  }

  get completed() {
    return this.segStatus && this.segStatus.transcriptionCompleted;
  }

  set completed(value: boolean) {
    annotationModule.updateSegStatus({
      transcriptionCompleted: value,
      lastUpdate: 'transcription'
    });
    snackbarModule.SET_TEXT({
      text: value ? '「作業完了」に設定しました' : '「作業完了」を解除しました'
    });
  }

  @Watch('selection', { deep: true })
  openEmbeddedDialog() {
    if (this.selection) {
      this.embeddedDialog = true;
    }
  }

  async save() {
    const user = authModule.currentUser;
    if (!user) return;
    const editor = this.$refs.editor as HTMLElement;
    console.log(this.text);
    const t: Transcription = {
      editedBy: user.uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      text: this.text,
      comment: this.comment,
      segId: this.$route.params.id
    };
    this.dialog = false;
    await db.collection('transcriptions').doc(this.id).set(t);
    snackbarModule.SET_TEXT({ text: '翻刻を保存しました' });
    annotationModule.setWatch({ value: true });
  }

  updateAnnotoriousInStore() {
    viewerModule.SET_ANNOTORIOUS({
      annotorious: this.embeds.map((a) => a.annotorious)
    });
  }

  selectEmbeds(embed: EmbeddedTranscription) {
    this.selectedEmbed = embed;
    viewerModule.SET_SELECTED_ANNOTORIOUS({ annotorious: embed.annotorious });
  }

  async execDeletion() {
    this.deleteEmbeddedDialog = false;
    if (this.selectedEmbed) {
      await db
        .collection('embeddedTranscriptions')
        .doc(this.selectedEmbed.id)
        .delete();
      snackbarModule.SET_TEXT({ text: '埋め込み翻刻を削除しました。' });
      this.updateAnnotoriousInStore();
    }
  }

  cancel() {
    this.comment = '';
    this.dialog = false;
  }

  @Watch('$route.params.id')
  async bind() {
    const segId = this.$route.params.id;
    const ref = db
      .collection('embeddedTranscriptions')
      .where('segId', '==', segId);
    await this.$bind('embeds', ref);
    this.updateAnnotoriousInStore();
  }

  mounted() {
    this.setText();
    this.bind();
  }
}
