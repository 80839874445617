















































































import { Segment, SegStatus } from '@/@types/global';
import { dialogModule } from '@/store';
import { db } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class PhotoCard extends Vue {
  seg: Segment | null = null;
  status: SegStatus | null = null;
  @Prop(String) id!: string;
  @Prop({ default: 2 }) elevation!: number;
  @Prop({ default: 280 }) width!: number;
  mouseOver = false;

  get thumbnail() {
    return '/thumbnails/' + this.seg!.index + '.jpg';
  }

  get countTranscription() {
    if (!this.seg) return 0;
    let count = 0;
    count += this.seg.countEmbeddedTranscription || 0;
    return count;
  }

  get completed() {
    return (
      this.status?.personAnnotationCompleted &&
      this.status.geoAnnotationCompleted &&
      this.status.transcriptionCompleted
    );
  }

  get static() {
    return Vue.prototype.$static;
  }

  openImage() {
    dialogModule.OPEN_IMAGE_VIEWER_DIALOG({ value: true, uri: this.seg!.uri });
  }

  async load() {
    const doc = await db.collection('segs').doc(this.id).get();
    this.seg = doc.data() as Segment;
    const statusDoc = await db.collection('segStatus').doc(this.id).get();
    this.status = statusDoc.data() as SegStatus;
  }

  mounted() {
    this.load();
  }
}
