


















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  annotationModule,
  authModule,
  snackbarModule,
  viewerModule
} from '@/store';
import PersonSearchDialog from './PersonSearchDialog.vue';
import PersonDeleteDialog from './PersonDeleteDialog.vue';
import { PersonAnnotation, PersonInfo } from '@/@types/global';
import firebase from 'firebase/app';
import { db } from '@/utils/firebase';
import { IAnnotation } from '@recogito/annotorious';
import PersonRegistrationRequestDialog from './PersonRegistrationRequestDialog.vue';
import PersonAnnotationDetailCard from './PersonAnnotationDetailCard.vue';

@Component({
  components: {
    PersonSearchDialog,
    PersonDeleteDialog,
    PersonRegistrationRequestDialog,
    PersonAnnotationDetailCard
  }
})
export default class PersonTab extends Vue {
  searchDialog = false;
  confirmDialog = false;
  deleteDialog = false;
  registrationRequestDialog = false;
  comment = '';
  selectedPerson: PersonInfo | null = null;
  selectedAnnotation: PersonAnnotation | null = null;
  annotations: PersonAnnotation[] = [];

  get selection() {
    return viewerModule.selection;
  }

  get selectedImageURI() {
    return viewerModule.selectedImageURI;
  }

  get currentUser() {
    return authModule.currentUser;
  }

  get segStatus() {
    return annotationModule.segStatus;
  }

  get completed() {
    return this.segStatus && this.segStatus.personAnnotationCompleted;
  }

  set completed(value: boolean) {
    annotationModule.updateSegStatus({
      personAnnotationCompleted: value,
      lastUpdate: 'personAnnotation'
    });
    snackbarModule.SET_TEXT({
      text: value ? '「作業完了」に設定しました' : '「作業完了」を解除しました'
    });
  }

  @Watch('selection', { deep: true })
  startAddingPerson() {
    if (this.selection) this.searchDialog = true;
  }

  addPerson(person: PersonInfo) {
    this.selectedPerson = person;
    this.searchDialog = false;
    this.confirmDialog = true;
  }

  closeSearchDialog() {
    this.searchDialog = false;
  }

  cancel() {
    this.confirmDialog = false;
  }

  updateAnnotoriousInStore() {
    viewerModule.SET_ANNOTORIOUS({
      annotorious: this.annotations.map((a) => a.annotorious)
    });
  }

  selectPersonAnnotation(anno: PersonAnnotation) {
    this.selectedAnnotation = anno;
    viewerModule.SET_SELECTED_ANNOTORIOUS({ annotorious: anno.annotorious });
  }

  requestRegistration() {
    this.searchDialog = false;
    this.registrationRequestDialog = true;
  }

  async deletePersonAnnotation(comment: string) {
    console.log(comment);
    if (!this.currentUser) return;
    this.deleteDialog = false;
    if (!this.selectedAnnotation) return;
    const id = this.selectedAnnotation.id;
    // update annotation before deletion
    this.selectedAnnotation.editedBy = this.currentUser.uid;
    this.selectedAnnotation.comment = comment;
    this.selectedAnnotation.timestamp =
      firebase.firestore.FieldValue.serverTimestamp();
    await db
      .collection('personAnnotations')
      .doc(id)
      .update(this.selectedAnnotation);
    await db.collection('personAnnotations').doc(id).delete();
    this.updateAnnotoriousInStore();
    snackbarModule.SET_TEXT({ text: 'アノテーションを削除しました' });
  }

  @Watch('$route.params.id')
  async bind() {
    const segId = this.$route.params.id;
    const ref = db.collection('personAnnotations').where('segId', '==', segId);
    await this.$bind('annotations', ref);
    this.updateAnnotoriousInStore();
  }

  get selectionToAnnotorious(): IAnnotation | null {
    if (!this.selection) return null;
    return {
      '@context': 'http://www.w3.org/ns/anno.jsonld',
      type: 'Annotation',
      target: this.selection!.target,
      body: [
        {
          value: this.selectedPerson!.name,
          purpose: 'commenting',
          type: 'TextualBody'
        }
      ],
      id: this.selectedImageURI,
      imageId: this.selectedImageURI
    };
  }

  async confirm() {
    this.confirmDialog = false;
    const p = this.selectedPerson;
    if (!p || !this.currentUser) return;
    const anno: PersonAnnotation = {
      segId: annotationModule.seg.id,
      label: p?.name,
      imageURI: viewerModule.selectedImageURI,
      personId: p.objectID,
      annotorious: this.selectionToAnnotorious as IAnnotation,
      editedBy: this.currentUser.uid,
      comment: this.comment,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isRegistrationRequest: false
    };
    await db.collection('personAnnotations').add(anno);
    this.updateAnnotoriousInStore();
    snackbarModule.SET_TEXT({ text: '人物情報を追加しました。' });
    annotationModule.setWatch({ value: true });
  }

  mounted() {
    this.bind();
  }
}
