





















import { Component, Vue, Watch } from 'vue-property-decorator';
import { History } from '@/@types/global';
import { db, getUserData } from '@/utils/firebase';
import HistoryEntry from './HistoryEntry.vue';
import { authModule, snackbarModule } from '@/store';

@Component({ components: { HistoryEntry } })
export default class HistoryTab extends Vue {
  histories: History[] = [];
  deleteDialog = false;
  delteTarget: History | null = null;

  get id() {
    return this.$route.params.id;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  async getUserData(history: History) {
    return await getUserData(history.uid);
  }

  openDeleteDialog(history: History) {
    this.delteTarget = history;
    this.deleteDialog = true;
  }

  async deleteHistory() {
    this.deleteDialog = false;
    if (this.delteTarget && this.isAdmin)
      await db.collection('histories').doc(this.delteTarget.id).delete();
    snackbarModule.SET_TEXT({ text: '履歴を削除しました' });
  }

  @Watch('id')
  bind() {
    const ref = db
      .collection('histories')
      .where('segId', '==', this.id)
      .orderBy('timestamp', 'desc')
      .limit(100);
    this.$bind('histories', ref);
  }

  mounted() {
    this.bind();
  }
}
