





import { History } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
import HistoryCard from '@/components/home/HistoryCard.vue';
@Component({
  components: { HistoryCard }
})
export default class PhotoUploadList extends Vue {
  histories: History[] = [];

  mounted() {
    const ref = db
      .collection('histories')
      .where('type', '==', 'photoUpload')
      .orderBy('timestamp', 'desc')
      .limit(50);
    this.$bind('histories', ref);
  }
}
