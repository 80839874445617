































import { IAnnotation, PersonAnnotation, Report } from '@/@types/global';
import {
  annotationModule,
  authModule,
  snackbarModule,
  viewerModule
} from '@/store';
import { db } from '@/utils/firebase';
import firebase from 'firebase/app';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PersonRegistrationRequestDialog extends Vue {
  @Prop(Boolean) value = false;
  @Prop(String) selectedImageURI!: string;
  name = '';
  wikipedia = '';
  comment = '';

  get open() {
    return this.value;
  }

  set open(value: boolean) {
    this.$emit('cancel');
  }

  clearFields() {
    this.name = '';
    this.wikipedia = '';
    this.comment = '';
  }

  cancel() {
    this.$emit('cancel');
    this.clearFields();
  }

  async submit() {
    this.$emit('submit');
    const currentUser = authModule.currentUser;
    if (!currentUser) return;
    const anno: PersonAnnotation = {
      segId: annotationModule.seg.id,
      label: this.name,
      imageURI: viewerModule.selectedImageURI,
      annotorious: viewerModule.selectionToAnnotorious as IAnnotation,
      editedBy: currentUser.uid,
      comment: this.comment,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      wikipedia: this.wikipedia,
      isRegistrationRequest: true
    };
    await db.collection('personAnnotations').add(anno);
    this.clearFields();
    snackbarModule.SET_TEXT({ text: 'データベース登録依頼を送信しました。' });
  }
}
