













import { db } from '@/utils/firebase';
import { IAnnotation } from '@recogito/annotorious';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class SegList extends Vue {
  segs: IAnnotation[] = [];

  imageURI(seg: IAnnotation) {
    const selector = seg.target.selector.value;
    const matched = selector?.match(/^xywh=pixel:(.+),(.+),(.+),(.+)$/);
    matched?.shift();
    const xywh = matched?.map((m: string) => parseInt(m)).join(',');
    return `https://eiichi.shibusawa.or.jp/denkishiryo-bekkan/api/images/iiif/${seg.imageId}/${xywh}/max/0/default.jpg`;
  }

  async mounted() {
    const ref = db.collection('segs').orderBy('index');
    await this.$bind('segs', ref);
  }
}
