import { authModule, dialogModule } from './../store/index';
import { AnnotationType, Chapter, History, PersonAnnotation, SegStatus } from '@/@types/global';
import { db } from './firebase';

export async function getFBDoc<T>(collection: string, id: string): Promise<T> {
    const doc = await db.collection(collection).doc(id).get();
    return doc.data() as T;
}

export async function getFBCollection<T>(collection: string): Promise<T[]> {
    const col = await db.collection(collection).get();
    return col.docs.map(doc => doc.data() as T);
}

export function historyTypeLabel(h: History) {
    switch (h.type) {
        case 'geo':
            return '🗾  位置情報を設定';
        case 'transcription':
            return '✍️  翻刻文を入力';
        case 'embeddedTranscription':
            return '✍️  翻刻文（埋め込み）を入力';
        case 'personAnnotation': {
            const anno = h.data as PersonAnnotation;
            return anno.isRegistrationRequest ? '👤 人物（データベース登録依頼中）をタグ付け' : '👤 人物をタグ付け';
        }
        case 'photoUpload':
            return '📷 写真をアップロード';
        case 'reference':
            return '📕 参考資料を追加';
        case 'post':
            return '💬 コメントを投稿';
        case 'segStatus': {
            const status = h.data as SegStatus;
            switch (status.lastUpdate) {
                case 'personAnnotation':
                    return status.personAnnotationCompleted
                        ? '👤「人物をタグ付け」作業完了に変更'
                        : '👤「人物をタグ付け」作業完了を解除';
                case 'geo':
                    return status.geoAnnotationCompleted
                        ? '🗾「位置情報を特定」作業完了に変更'
                        : '🗾「位置情報を特定」作業完了を解除';
                case 'transcription':
                    return status.transcriptionCompleted
                        ? '✍️ 「文字情報を翻刻」作業完了に変更'
                        : '✍️ 「文字情報を翻刻」作業完了を解除';
                default:
                    return status.personAnnotationCompleted
                        ? '👤「人物をタグ付け」作業完了に変更'
                        : '👤「人物をタグ付け」作業完了を解除';
            }
        }
        default:
            return '不明なアクション';
    }
}

export const chapters: Chapter[] = [
    { index: 0, from: 3, to: 38, title: '前期' },
    { index: 1, from: 41, to: 80, title: '中期1' },
    { index: 2, from: 81, to: 124, title: '中期2' },
    { index: 3, from: 127, to: 177, title: '後期1' },
    { index: 4, from: 178, to: 228, title: '後期2' },
    { index: 5, from: 231, to: 260, title: '諸影' },
    { index: 6, from: 263, to: 270, title: '邸宅' },
    { index: 7, from: 273, to: 292, title: '永眠と記念事業' }
];

export function getChapterOfPage(page: number) {
    return chapters.find(c => c.from <= page && page <= c.to);
}

export function requireLogin() {
    if (authModule.currentUser) {
        return;
    } else {
        return dialogModule.OPEN_SIGN_IN_DIALOG({ open: true });
    }
}

export function annotationType2CollectionName(annoType: AnnotationType) {
    switch (annoType) {
        case 'embeddedTranscription':
            return 'embeddedTranscriptions';
        case 'geo':
            return 'geo';
        case 'personAnnotation':
            return 'personAnnotations';
        case 'photoUpload':
            return 'photoUploads';
        case 'post':
            return 'posts';
        case 'reference':
            return 'referenceLinks';
        case 'transcription':
            return 'transcriptions';
        default:
            throw new Error(annoType);
    }
}