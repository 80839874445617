


























import { User } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
import OpenableUserName from '../molecules/OpenableUserName.vue';
@Component({ components: { OpenableUserName } })
export default class Leadearboard extends Vue {
  users: User[] = [];

  bind() {
    const ref = db.collection('users').orderBy('likeCount', 'desc').limit(100);
    this.$bind('users', ref);
  }

  mounted() {
    this.bind();
  }
}
