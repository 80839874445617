



















































import { History, Reference, ReferenceLink, User } from '@/@types/global';
import {
  getHistoryByAnnoId,
  getReferenceData,
  getUserData
} from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OpenableUserName from '../molecules/OpenableUserName.vue';
import LikeBtn from '../molecules/LikeBtn.vue';
import LikeCounter from '../molecules/LikeCounter.vue';
import { authModule } from '@/store';
@Component({ components: { OpenableUserName, LikeBtn, LikeCounter } })
export default class ReferenceCard extends Vue {
  @Prop(Object) link!: ReferenceLink;
  @Prop({ default: true }) showUser!: boolean;
  reference: Reference | null = null;
  user: User | null = null;
  history: History | null = null;

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isMyLink() {
    return (
      authModule.currentUser && this.link.editedBy == authModule.currentUser.uid
    );
  }

  get media() {
    if (!this.reference) return '';
    switch (this.reference.media) {
      case 'book':
        return '書籍';
      case 'inbook':
        return '書籍の一部';
      case 'paper':
        return '記事・論文';
      case 'webpage':
        return 'Webページ';
      default:
        return 'その他';
    }
  }

  async created() {
    if (this.showUser) {
      this.reference = await getReferenceData(this.link.referenceId);
      this.user = await getUserData(this.link.editedBy);
      this.history = await getHistoryByAnnoId(this.link.id!);
    }
  }
}
