








import HistoryEntry from '@/components/annotate/HistoryEntry.vue';
import { authModule } from '@/store';
import { db } from '@/utils/firebase';
import { Component, Vue, Watch } from 'vue-property-decorator';
@Component({ components: { HistoryEntry } })
export default class MyHistory extends Vue {
  histories: History[] = [];

  get currentUser() {
    return authModule.currentUser;
  }

  @Watch('currentUser')
  bind() {
    if (this.currentUser) {
      const ref = db
        .collection('histories')
        .where('uid', '==', this.currentUser?.uid)
        .orderBy('timestamp', 'desc')
        .limit(100);
      this.$bind('histories', ref);
    } else {
      this.histories = [];
    }
  }

  mounted() {
    this.bind();
  }
}
