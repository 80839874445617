











import { LikeEntry } from '@/@types/global';
import { authModule } from '@/store';
import { db } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class LikeBtn extends Vue {
  fontSize = 30;
  @Prop(String) ownerId!: string;
  @Prop(String) collection!: string;
  @Prop(String) id!: string;
  likeEntry: LikeEntry = { collection: '', docId: '', likers: {} };

  get style() {
    return `font-size: ${this.fontSize || 30} px;`;
  }

  get currentUser() {
    return authModule.currentUser;
  }

  get likedByMe(): boolean {
    if (!this.currentUser) return false;
    if (!this.likeEntry || !this.likeEntry.likers) return false;
    return !!this.likeEntry.likers[this.currentUser.uid];
  }

  get ref() {
    const likeEntryId = `${this.collection}-${this.id}`;
    return db.collection('likeEntries').doc(likeEntryId);
  }

  get count() {
    const likers = this.likeEntry.likers;
    return Object.keys(likers).filter((k) => likers[k]).length;
  }

  get isCurrentUserOwner() {
    return this.ownerId == this.currentUser!.uid;
  }

  bind() {
    this.$bind('likeEntry', this.ref);
  }

  async toggleLike() {
    if (!this.currentUser) return;
    return await db.runTransaction(async (t) => {
      const doc = await t.get(this.ref);
      if (doc.exists) {
        // update exising entry
        const update: { [path: string]: boolean } = {};
        const likeEntry = doc.data() as LikeEntry;
        const liked = !!likeEntry.likers[this.currentUser!.uid];
        update['likers.' + this.currentUser!.uid] = !liked;
        t.update(this.ref, update);
      } else {
        // create a new doc
        const likers: { [uid: string]: boolean } = {};
        likers[this.currentUser!.uid] = true;
        const likeEntry: LikeEntry = {
          collection: this.collection,
          docId: this.id,
          likers
        };
        t.set(this.ref, likeEntry);
      }
    });
  }

  mounted() {
    this.bind();
  }
}
