import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
// import Annotate from '../views/Annotate.vue';
import Segmentation from '../views/Segmentation.vue';
import SegList from '../views/SegList.vue';
import PhotoList from '../views/PhotoList.vue';
import Search from '../views/Search.vue';
import Reader from '../views/Reader.vue';
import People from '../views/People.vue';
import PersonAnnotationList from '../views/PersonAnnotationList.vue';
import PhotoUploadList from '../views/PhotoUploadList.vue';
import ReferenceList from '../views/ReferenceList.vue';
import ReferenceListDetail from '../views/ReferenceListDetail.vue';
import DefaultLayout from '../views/DefaultLayout.vue';
import StaticLayout from '../views/StaticLayout.vue';
import PhotoMap from '../views/PhotoMap.vue';
import Timeline from '../views/Timeline.vue';
import InfoTab from '@/components/annotate/InfoTab.vue';
import GeoTab from '@/components/annotate/GeoTab.vue';
import TranscriptionTab from '@/components/annotate/TranscriptionTab.vue';
import HistoryTab from '@/components/annotate/HistoryTab.vue';
import PeopleTab from '@/components/annotate/PeopleTab.vue';
import PhotoUploadTab from '@/components/annotate/PhotoUploadTab.vue';
import ReferencesTab from '@/components/annotate/ReferencesTab.vue';
import DiscussionTab from '@/components/annotate/DiscussionTab.vue';
import About from '@/views/About.vue';
import AboutStatic from '@/views/AboutStatic.vue';
import IIIFViewer from '@/views/IIIFViewer.vue';
import MyHistory from '@/views/MyHistory.vue';
import AdminView from '@/views/AdminView.vue';


const Annotate = () => import('../views/Annotate.vue');

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: '/annotate',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'about',
        name: 'About',
        component: About
      },
      {
        path: 'search',
        name: 'Search',
        component: Search
      },
      {
        path: 'reader/:chapter',
        name: 'Reader',
        component: Reader
      },
      {
        path: 'photolist/:index',
        name: 'PhotoList',
        component: PhotoList
      },
      {
        path: 'photo-map',
        name: 'PhotoMap',
        component: PhotoMap
      },
      {
        path: 'timeline',
        name: 'Timeline',
        component: Timeline
      },
      {
        path: 'people-list',
        name: 'PeopleList',
        component: People
      },
      {
        path: 'people-list/:personId',
        name: 'PersonAnnotationList',
        component: PersonAnnotationList
      },
      {
        path: 'reference-list/',
        name: 'ReferenceList',
        component: ReferenceList
      },
      {
        path: 'reference-list/:referenceId',
        name: 'ReferenceListDetail',
        component: ReferenceListDetail
      },
      {
        path: 'photo-upload-list',
        name: 'PhotoUploadList',
        component: PhotoUploadList
      },
      {
        path: 'my-history',
        name: 'MyHistory',
        component: MyHistory
      },
      {
        path: 'admin',
        name: 'Admin',
        component: AdminView
      }
    ]
  },
  {
    path: '/annotate/_photo/:id/:tab',
    redirect: '/annotate/photo/:id/:tab'
  },
  {
    path: '/annotate/photo/:id/',
    component: Annotate,
    children: [
      {
        path: 'info',
        name: 'Info',
        component: InfoTab,
      },
      {
        path: 'people',
        name: 'People',
        component: PeopleTab,
      },
      {
        path: 'geo',
        name: 'Geo',
        component: GeoTab,
      },
      {
        path: 'transcribe',

        name: 'Transcription',
        component: TranscriptionTab,
      },
      {
        path: 'photo-upload',
        name: 'PhotoUpload',
        component: PhotoUploadTab,
      },
      {
        path: 'references',
        name: 'References',
        component: ReferencesTab,
      },
      {
        path: 'discusstion',
        name: 'Discussion',
        component: DiscussionTab,
      },
      {
        path: 'histories',
        name: 'Histories',
        component: HistoryTab,
      }
    ]
  },

  {
    path: '/segmentation/:id',
    name: 'Segmentation',
    component: Segmentation
  },
  {
    path: '/segs',
    name: 'SegList',
    component: SegList
  }
];

const staticRoutes: Array<RouteConfig> = [
  {
    path: '/supvol10',
    component: StaticLayout,
    children: [
      {
        path: '',
        name: 'AboutStatic',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: AboutStatic
      },
      {
        path: 'viewer',
        name: 'Viewer',
        component: IIIFViewer
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export const routerStatic = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: staticRoutes
});

export default router;

