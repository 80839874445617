




































import { authModule, dialogModule, snackbarModule } from '@/store';
import { db } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class ProfileEditDialog extends Vue {
  @Prop(Boolean) value = false;
  profile = '';

  get open() {
    this.loadProfile();
    return dialogModule.profileEditDialog;
  }

  set open(value: boolean) {
    dialogModule.OPEN_PROFILE_EDIT_DIALOG({ open: value });
  }

  get currentUser() {
    return authModule.currentUser;
  }

  loadProfile() {
    if (this.currentUser) {
      this.profile = this.currentUser!.profile;
    }
  }

  async save() {
    this.open = false;
    if (this.currentUser) {
      const uid = this.currentUser.uid;
      await db.collection('users').doc(uid).update({ profile: this.profile });
    }
    snackbarModule.SET_TEXT({ text: 'プロフィールを変更しました' });
  }

  cancel() {
    this.loadProfile();
    this.open = false;
  }

  mounted() {
    this.loadProfile();
  }
}
