








import { dialogModule } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import LikersDialog from './LikersDialog.vue';
import ProfileDisplayDialog from './ProfileDisplayDialog.vue';
import ProfileEditDialog from './ProfileEditDialog.vue';
import ImageViewerDialog from '@/components/dialogs/ImageViewerDialog.vue';

@Component({
  components: {
    ProfileDisplayDialog,
    LikersDialog,
    ProfileEditDialog,
    ImageViewerDialog
  }
})
export default class DialogContainer extends Vue {
  get profileEditDialog() {
    return dialogModule.profileEditDialog;
  }

  set profileEditDialog(open: boolean) {
    dialogModule.OPEN_PROFILE_EDIT_DIALOG({ open });
  }

  get profileDisplayDialog() {
    return dialogModule.profileDisplayDialog;
  }

  set profileDisplayDialog(value: boolean) {
    dialogModule.OPEN_PROFILE_DISPLAY_DIALOG({ open: value });
  }

  get likersDialog() {
    return dialogModule.likersDialog;
  }

  set likersDialog(value: boolean) {
    dialogModule.OPEN_LIKERS_DIALOG({ open: value });
  }
}
