








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import 'instantsearch.css/themes/satellite-min.css';
import { PersonAnnotation, PersonInfo } from '@/@types/global';
import { viewerModule } from '@/store';

@Component
export default class PersonDeleteDialog extends Vue {
  @Prop(Boolean) value = false;
  @Prop(Object) selectedAnnotation!: PersonAnnotation;
  comment = '';

  get open() {
    return this.value;
  }

  set open(value: boolean) {
    if (!value) this.cancel();
  }

  get selectedImageURI() {
    return viewerModule.selectedImageURI;
  }

  confirm() {
    this.$emit('confirm', this.comment);
  }

  cancel() {
    this.comment = '';
    this.$emit('cancel');
  }
}
