























import { Post, User, History } from '@/@types/global';
import { authModule } from '@/store';
import { getHistoryByAnnoId, getUserData } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LikeBtn from '../molecules/LikeBtn.vue';
import LikeCounter from '../molecules/LikeCounter.vue';
@Component({ components: { LikeBtn, LikeCounter } })
export default class PostListItem extends Vue {
  @Prop(Object) post!: Post;
  user: User | null = null;
  history: History | null = null;

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isMyPost() {
    return (
      authModule.currentUser && authModule.currentUser.uid == this.post.editedBy
    );
  }

  get text() {
    return this.post.comment.replace('\n', '<br>');
  }

  async mounted() {
    this.user = await getUserData(this.post.editedBy);
    this.history = await getHistoryByAnnoId(this.post.id!);
  }
}
