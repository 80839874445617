





import { PhotoUpload } from '@/@types/global';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class PhotoUploadHistoryMedia extends Vue {
  @Prop(Object) anno!: PhotoUpload;

  mounted() {
    this.anno;
  }
}
