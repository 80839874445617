














import Vue from 'vue';
import Component from 'vue-class-component';
import { dialogModule } from '@/store';

@Component
export default class MessageDialog extends Vue {
  get open() {
    return dialogModule.messageDialog;
  }

  set open(value: boolean) {
    dialogModule.OPEN_MESSAGE_DIALOG({ value });
  }

  get title() {
    return dialogModule.messageDialogTitle;
  }

  get message() {
    return dialogModule.messageDialogMsg;
  }
}
