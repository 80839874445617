




















































import { annotationModule } from '@/store';
import { getChapterOfPage } from '@/utils/utils';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class InfoTab extends Vue {
  get seg() {
    return annotationModule.seg;
  }

  get originalImageLink() {
    const id = this.seg.imageId;
    return `https://eiichi.shibusawa.or.jp/denkishiryo-bekkan/api/images/iiif/${id}/full/max/0/default.jpg`;
  }

  get chapterNum() {
    const page = parseInt(this.seg.metadata[6]);
    return getChapterOfPage(page)?.index;
  }
}
