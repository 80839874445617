















import { PersonAnnotation, PersonInfo } from '@/@types/global';
import { Component, Prop, Vue } from 'vue-property-decorator';
import algoliasearch from 'algoliasearch';
@Component
export default class PersonAnnotationHistoryMedia extends Vue {
  @Prop(Object) anno!: PersonAnnotation;
  client = algoliasearch('7ZRRU66ATT', 'b7771a540f82219e0a99982aadabe3cf');
  index = this.client.initIndex('person');
  person: PersonInfo | null = null;

  async mounted() {
    if (!this.anno.isRegistrationRequest) {
      this.person = await this.index.getObject(this.anno.personId);
    }
  }
}
