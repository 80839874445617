

































import { Segment } from '@/@types/global';
import { db } from '@/utils/firebase';
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class RandomPhoto extends Vue {
  seg: Segment | null = null;

  get thumbnail() {
    return '/thumbnails/' + this.seg!.index + '.jpg';
  }

  async loadPhoto() {
    const index = Math.floor(Math.random() * 733);
    const segs = await db
      .collection('segs')
      .where('index', '==', index)
      .limit(1)
      .get();
    if (segs.docs[0]) {
      this.seg = segs.docs[0].data() as Segment;
    }
  }

  mounted() {
    this.loadPhoto();
  }
}
