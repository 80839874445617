



















































































import { PhotoUpload } from '@/@types/global';
import { annotationModule, authModule, snackbarModule } from '@/store';
import { db } from '@/utils/firebase';
import firebase from 'firebase/app';
import { Component, Vue, Watch } from 'vue-property-decorator';
import PhotoUploadCard from './PhotoUploadCard.vue';

@Component({ components: { PhotoUploadCard } })
export default class PhotoUploadTab extends Vue {
  uploadDialog = false;
  deleteDialog = false;
  deletionCandidate: PhotoUpload | null = null;
  dataURL = '';
  file: File | null = null;
  comment = '';
  reader: FileReader = new FileReader();
  storageRef = firebase.storage().ref();
  agreed = false;
  ccbysa = false;
  ownership = false;
  privacy = false;
  photoAllowed = false;
  uploading = false;
  uploads: PhotoUpload[] = [];

  get currentUser() {
    return authModule.currentUser;
  }

  openDeleteDialog(upload: PhotoUpload) {
    this.deletionCandidate = upload;
    this.deleteDialog = true;
  }

  async deleteUpload() {
    if (this.deletionCandidate && this.currentUser) {
      await db
        .collection('photoUploads')
        .doc(this.deletionCandidate.id)
        .delete();
      snackbarModule.SET_TEXT({ text: '写真を削除しました' });
    }
    this.deleteDialog = false;
  }

  fileSelected(e: InputEvent) {
    const el = e.target as HTMLInputElement;
    const file = el.files![0];
    if (!file) return;
    if (!/^image\/(png|jpeg|gif)$/.test(file.type)) {
      el.value = '';
      return alert('PNG/JPEG/GIF形式以外のファイルはアップロードできません。');
    }
    if (file.size > 1024 * 1024 * 10) {
      el.value = '';
      return alert('ファイルサイズは10MBにしてください。');
    }
    this.file = file;
    this.reader.readAsDataURL(file);
  }

  setupFileReader() {
    this.reader.onload = () => {
      this.dataURL = this.reader.result as string;
    };
  }

  clear() {
    this.file = null;
    this.dataURL = '';
    this.agreed = false;
    this.ownership = false;
    this.privacy = false;
    this.photoAllowed = false;
  }

  cancel() {
    this.clear();
    this.uploadDialog = false;
  }

  submit() {
    if (!this.file) return;
    const id = this.$route.params.id;
    const time = new Date().getTime();
    const filepath = `images/${id}_${time}_${this.file.name}`;
    const uploadTask = this.storageRef.child(filepath).put(this.file);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            this.uploading = true;
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        this.uploading = false;
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
        snackbarModule.SET_TEXT({
          text: 'アップロードがキャンセルされました。'
        });
      },
      async () => {
        this.uploadDialog = false;
        // Upload completed successfully, now we can get the download URL
        snackbarModule.SET_TEXT({
          text: 'ファイルのアップロードが完了しました。'
        });
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        console.log('File available at', downloadURL);
        this.addFirestoreEntry(downloadURL);
        this.clear();
        annotationModule.setWatch({ value: true });
      }
    );
  }

  async addFirestoreEntry(photoURL: string) {
    const currentUser = authModule.currentUser;
    if (!currentUser) return;
    const upload: PhotoUpload = {
      photoURL,
      editedBy: currentUser.uid,
      segId: this.$route.params.id,
      comment: this.comment,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    await db.collection('photoUploads').add(upload);
  }

  @Watch('$route.params.id')
  async bind() {
    const ref = db
      .collection('photoUploads')
      .where('segId', '==', this.$route.params.id)
      .orderBy('timestamp', 'desc');
    await this.$bind('uploads', ref);
  }

  mounted() {
    this.bind();
    this.setupFileReader();
  }
}
// https://firebasestorage.googleapis.com/v0/b/shibusawa-8a04b.appspot.com/o/images%2Fc660421a-5cea-4a9e-b16a-3f6c92b981df_1632818294931_L001212.min.png?alt=media
// https://firebasestorage.googleapis.com/v0/b/shibusawa-8a04b.appspot.com/o/images%2Fthumbnails%2Fc660421a-5cea-4a9e-b16a-3f6c92b981df_1632818294931_L001212.min_800x800.jpeg?alt=media
