



























import { Reference, ReferenceLink } from '@/@types/global';
import { authModule, snackbarModule } from '@/store';
import { db } from '@/utils/firebase';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ReferenceCard from './ReferenceCard.vue';
import ReferenceSelectionDialog from './ReferenceSelectionDialog.vue';

@Component({ components: { ReferenceCard, ReferenceSelectionDialog } })
export default class ReferencesTab extends Vue {
  tabs = 0;
  links: ReferenceLink[] = [];
  dialog = false;
  deleteDialog = false;
  deletionCandidate: ReferenceLink | null = null;

  get currentUser() {
    return authModule.currentUser;
  }

  openDeleteDialog(link: ReferenceLink) {
    this.deletionCandidate = link;
    this.deleteDialog = true;
  }

  async deleteLink() {
    if (this.deletionCandidate && this.currentUser) {
      await db
        .collection('referenceLinks')
        .doc(this.deletionCandidate.id)
        .delete();
      snackbarModule.SET_TEXT({ text: '参考資料を削除しました' });
    }
    this.deleteDialog = false;
  }

  @Watch('$route.params.id')
  async bind() {
    const id = this.$route.params.id;
    const ref = db
      .collection('referenceLinks')
      .where('segId', '==', id)
      .orderBy('timestamp', 'desc');
    await this.$bind('links', ref);
  }

  mounted() {
    this.bind();
  }
}
