


















































import { Component, Vue } from 'vue-property-decorator';
import DialogContainer from '@/components/dialogs/DialogContainer.vue';

@Component({
  components: { DialogContainer }
})
export default class extends Vue {
  links = [
    {
      label: '渋沢栄一フォトグラフ',
      uri: '/'
    },
    {
      label: '渋沢栄一記念財団',
      uri: 'https://www.shibusawa.or.jp/index.html'
    },
    {
      label: 'デジタル版『渋沢栄一伝記資料』',
      uri: 'https://eiichi.shibusawa.or.jp/denkishiryo/digital/main/index.php'
    },
    {
      label: '渋沢栄一ダイアリー',
      uri: 'https://shibusawa-dlab.github.io/app1/'
    },
    {
      label: '総合資料学の創成',
      uri: 'https://www.metaresource.jp/'
    }
  ];

  collection = [
    {
      label: '前期',
      count: 0,
      index: 0
    },
    {
      label: '中期1',
      count: 0,
      index: 1
    },
    {
      label: '中期2',
      count: 0,
      index: 2
    },
    {
      label: '後期1',
      count: 0,
      index: 3
    },
    {
      label: '後期2',
      count: 0,
      index: 4
    },
    {
      label: '諸影',
      count: 0,
      index: 5
    },
    {
      label: '邸宅',
      count: 0,
      index: 6
    },
    {
      label: '永眠と記念事業',
      count: 0,
      index: 7
    }
  ];
}
