import Vue from "vue";
import Vuex from "vuex";
import { getModule } from 'vuex-module-decorators';
import { vuexfireMutations } from 'vuexfire';
import AnnotationModule from './modules/annotation';
import AuthModule from './modules/auth';
import DialogModule from './modules/dialog';
import ScrollModule from './modules/scroll';
import SnackbarModule from './modules/snackbar';
import ViewerModule from './modules/viewer';

Vue.use(Vuex);

const store = new Vuex.Store({
  mutations: vuexfireMutations,
  modules: { auth: AuthModule, dialog: DialogModule, snackbar: SnackbarModule, annotation: AnnotationModule, viewer: ViewerModule, scroll: ScrollModule },
});

export default store;
export const authModule = getModule(AuthModule, store);
export const dialogModule = getModule(DialogModule, store);
export const snackbarModule = getModule(SnackbarModule, store);
export const annotationModule = getModule(AnnotationModule, store);
export const viewerModule = getModule(ViewerModule, store);
export const scrollModule = getModule(ScrollModule, store);
