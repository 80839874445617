




























import { PersonAnnotation, User } from '@/@types/global';
import { getUserData } from '@/utils/firebase';
import algoliasearch from 'algoliasearch';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OpenableUserName from '../molecules/OpenableUserName.vue';
@Component({ components: { OpenableUserName } })
export default class PersonAnnotationDetailCard extends Vue {
  @Prop(Object) anno!: PersonAnnotation;
  user: User | null = null;
  personData: { objectID: string; bio: string } | null = null;
  searchClient = algoliasearch(
    '7ZRRU66ATT',
    'b7771a540f82219e0a99982aadabe3cf'
  );
  index = this.searchClient.initIndex('person');

  @Watch('anno', { deep: true })
  async loadUserData() {
    this.user = await getUserData(this.anno.editedBy);
  }

  @Watch('anno', { deep: true })
  async loadPersonData() {
    try {
      this.personData = await this.index.getObject(this.anno.personId!);
    } catch (err) {
      this.personData = null;
    }
  }

  mounted() {
    this.loadUserData();
    this.loadPersonData();
  }
}
