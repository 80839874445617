


























































































































import { Reference, ReferenceLink } from '@/@types/global';
import { Component, Prop, Vue } from 'vue-property-decorator';
import algoliasearch from 'algoliasearch/lite';

import ReferenceCard from './ReferenceCard.vue';
import { authModule, snackbarModule } from '@/store';
import firebase from 'firebase/app';
import { db } from '@/utils/firebase';
@Component({ components: { ReferenceCard } })
export default class ReferenceSelectionDialog extends Vue {
  @Prop(Boolean) value = false;
  tabs = 0;
  query = '';
  selected: Reference | null = null;
  showHits = false;
  mediaTypes = [
    { text: '書籍', value: 'book' },
    { text: '記事・論文', value: 'paper' },
    { text: 'Webページ', value: 'webpage' },
    { text: 'その他', value: 'other' }
  ];
  valid = true;
  media: 'book' | 'inbook' | 'paper' | 'webpage' | 'other' = 'book';
  title = '';
  author = '';
  journal = '';
  volume = '';
  number = '';
  pages = '';
  url = '';
  publisher = '';
  year = '';
  comment = '';
  titleRules = [(v: string) => !!v || 'タイトルは必須項目です。'];
  searchClient = algoliasearch(
    '7ZRRU66ATT',
    'b7771a540f82219e0a99982aadabe3cf'
  );

  get dialog() {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit('close');
  }

  get isSubmitReady() {
    if (this.tabs == 0) {
      return this.selected;
    } else {
      return this.title && this.title != '';
    }
  }

  get currentUser() {
    return authModule.currentUser;
  }

  selectReference(ref: Reference) {
    this.selected = ref;
    this.showHits = false;
  }

  mediaLabel(hit: Reference) {
    switch (hit.media) {
      case 'book':
        return '書籍';
      case 'inbook':
        return '書籍の一部';
      case 'paper':
        return '学術論文';
      case 'webpage':
        return 'Webページ';
      default:
        return 'その他';
    }
  }

  hideHits() {
    setTimeout(() => (this.showHits = false), 300);
  }

  clear() {
    this.title = '';
    this.author = '';
    this.journal = '';
    this.volume = '';
    this.number = '';
    this.pages = '';
    this.url = '';
    this.year = '';
    this.comment = '';
    this.selected = null;
  }

  cancel() {
    this.$emit('close');
    this.clear();
  }

  submit() {
    this.$emit('close');
    if (this.tabs == 0) {
      this.submitLink();
    } else {
      this.submitReferenceWithLink();
    }
    this.clear();
  }

  async submitReferenceWithLink() {
    if (!this.currentUser) return;
    const batch = db.batch();
    const referenceRef = db.collection('references').doc();
    const linkRef = db.collection('referenceLinks').doc();
    const referenceId = referenceRef.id;
    const reference: Reference = {
      editedBy: this.currentUser.uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      media: this.media,
      title: this.title,
      author: this.author,
      journal: this.journal,
      volume: this.volume,
      number: this.number,
      publisher: this.publisher,
      pages: this.pages,
      url: this.url,
      year: this.year
    };
    const link: ReferenceLink = {
      editedBy: this.currentUser.uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      segId: this.$route.params.id,
      comment: this.comment,
      referenceId
    };
    batch.set(referenceRef, reference);
    batch.set(linkRef, link);
    await batch.commit();
    snackbarModule.SET_TEXT({ text: '参考資料を追加しました' });
  }

  async submitLink() {
    if (!this.currentUser || !this.selected) return;
    const link: ReferenceLink = {
      editedBy: this.currentUser.uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      segId: this.$route.params.id,
      comment: this.comment,
      referenceId: this.selected.objectID!
    };
    await db.collection('referenceLinks').add(link);
    snackbarModule.SET_TEXT({ text: '参考資料を追加しました' });
  }
}
