import { authModule } from '@/store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { db } from '@/utils/firebase';
import { bindFirebaseRef } from '@/utils/bindFirebaseRef';
import { Segment, SegStatus, Watch } from '@/@types/global';
import firebase from 'firebase/app';

@Module({ namespaced: true, name: 'annotation' })
export default class AnnotationModule extends VuexModule {

    seg: Segment = {
        id: '',
        uri: '',
        index: 0,
        imageId: '',
        metadata: [],
        countEmbeddedTranscription: 0,
        countPosts: 0,
        countReferences: 0,
        countPeople: 0,
        countPhotos: 0,
        contentSeqId: ''
    };
    segStatus: SegStatus = {
        personAnnotationCompleted: false,
        transcriptionCompleted: false,
        geoAnnotationCompleted: false,
        editedBy: 'system',
        comment: '',
        lastUpdate: 'personAnnotation',
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    watch: Watch = {
        segId: "",
        uids: {}
    };
    segs: Segment[] = [];

    get currentIndex() {
        return this.segs.findIndex(seg => seg.id === this.seg.id);
    }

    get prevSeg() {
        return this.segs[this.currentIndex - 1];
    }

    get nextSeg() {
        return this.segs[this.currentIndex + 1];
    }

    @Action
    async bindSeg(payload: { id: string; }) {
        const ref = db.collection('segs').doc(payload.id);
        const statusRef = db.collection('segStatus').doc(payload.id);
        const watchRef = db.collection('watch').doc(payload.id);
        await bindFirebaseRef(this.context, 'seg', ref);
        await bindFirebaseRef(this.context, 'segStatus', statusRef);
        await bindFirebaseRef(this.context, 'watch', watchRef);
    }

    @Action
    async bindCollection() {
        const ref = db.collection('segs').orderBy('index', 'desc');
        await bindFirebaseRef(this.context, 'segs', ref);
    }

    @Action
    async updateSegStatus(payload: Partial<SegStatus>) {
        const currentUser = authModule.currentUser;
        if (this.segStatus.id && currentUser) {
            payload.editedBy = currentUser.uid;
            payload.timestamp = firebase.firestore.FieldValue.serverTimestamp();
            await db.collection('segStatus').doc(this.segStatus.id).update(payload);
        }
    }

    @Action
    async setWatch(payload: { value: boolean; }) {
        const currentUser = authModule.currentUser;
        if (this.seg && currentUser) {
            const ref = db.collection('watch').doc(this.seg.id);
            const update: { uids: { [uid: string]: boolean; }; } = { uids: {} };
            update.uids[currentUser.uid] = payload.value;
            await ref.set(update, { merge: true });
        }
    }

    @Action
    async toggleWatch() {
        const currentUser = authModule.currentUser;
        if (this.seg && currentUser) {
            const ref = db.collection('watch').doc(this.seg.id);
            const uid = currentUser.uid;
            db.runTransaction((transaction) => {
                return transaction.get(ref).then(doc => {
                    let value = false;
                    if (doc.exists) {
                        const watch = doc.data() as Watch;
                        value = !!watch.uids[uid];
                    }
                    const update: { uids: { [uid: string]: boolean; }; } = { uids: {} };
                    update.uids[uid] = !value;
                    transaction.set(ref, update, { merge: true });

                });
            });
        }
    }

}