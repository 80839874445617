



















import { Reference, ReferenceLink } from '@/@types/global';
import { getReferenceData } from '@/utils/firebase';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class ReferenceHistoryMedia extends Vue {
  @Prop(Object) anno!: ReferenceLink;
  reference: Reference | null = null;
  mounted() {
    this.anno;
  }

  get media() {
    if (!this.reference) return '';
    switch (this.reference.media) {
      case 'book':
        return '書籍';
      case 'inbook':
        return '書籍の一部';
      case 'paper':
        return '記事・論文';
      case 'webpage':
        return 'Webページ';
      default:
        return 'その他';
    }
  }

  async created() {
    this.reference = await getReferenceData(this.anno.referenceId);
  }
}
