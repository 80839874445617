







import Vue from 'vue';
import Component from 'vue-class-component';
import { dialogModule } from '@/store';

@Component
export default class ImageViewerDialog extends Vue {
  get open() {
    return dialogModule.imageViewerDialog;
  }

  set open(value: boolean) {
    dialogModule.OPEN_IMAGE_VIEWER_DIALOG({ value });
  }

  get uri() {
    return dialogModule.imageViewerURI;
  }
}
