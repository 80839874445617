



















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import { Location, GeoLodItem, User } from '@/@types/global';
import 'leaflet-center-cross';
import { annotationModule, authModule, snackbarModule } from '@/store';
import { db, getUserData } from '@/utils/firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import { requireLogin } from '@/utils/utils';

const DefaultIcon = L.icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  iconSize: [26, 40],
  iconAnchor: [13, 40],
  popupAnchor: [0, -40]
});
L.Marker.prototype.options.icon = DefaultIcon;

@Component
export default class GeoTab extends Vue {
  location: Location | null = null;
  map!: L.Map;
  markers: L.Marker[] = [];
  query = '';
  errors: string[] = [];
  confirmDialog = false;
  confirmMsg = '';
  comment = '';
  screenshooter!: SimpleMapScreenshoter;
  geo: Location | null = null;

  get segStatus() {
    return annotationModule.segStatus;
  }

  panToGeo(geo: GeoLodItem) {
    const marker = geo.marker;
    if (!marker) return;
    marker.addTo(this.map);
    marker.openPopup();
    this.map.panTo(marker.getLatLng());
  }

  get hasGeoInfo() {
    return this.location && this.location.hasGeoInfo;
  }

  get completed() {
    return this.segStatus && this.segStatus.geoAnnotationCompleted;
  }

  set completed(value: boolean) {
    annotationModule.updateSegStatus({
      geoAnnotationCompleted: value,
      lastUpdate: 'geo'
    });
    snackbarModule.SET_TEXT({
      text: value ? '「作業完了」に設定しました' : '「作業完了」を解除しました'
    });
  }

  get currentUser() {
    return authModule.currentUser;
  }

  removeMarkers() {
    this.markers.forEach((m) => m.remove());
    this.markers = [];
  }

  createMarker(geo: GeoLodItem) {
    if (!geo.latitude || !geo.longitude) return;
    const marker = L.marker([geo.latitude, geo.longitude]).addTo(this.map);
    const template = `
            <b>${geo.body}</b><br/>
            <button class="v-btn v-btn--contained v-btn--is-elevated v-btn--has-bg theme--light v-size--small" id="button-submit" type="button">
                <span>この場所に設定</span>
            </button>
            `;
    marker.bindPopup(template);
    this.markers.push(marker);
    geo.marker = marker;
    marker.on('popupopen', (e: any) => {
      const container = e.popup._container as HTMLElement;
      const btn = container.getElementsByTagName('button')[0];
      this.map.panTo(marker.getLatLng());
      btn.addEventListener('click', () => {
        this.confirmSetLocation();
      });
    });
    return marker;
  }

  async searchGeoNLP() {
    if (this.query == '') return;
    const URL = `https://msearch.gsi.go.jp/address-search/AddressSearch?q=${this.query}`;
    const resp = await axios.get(URL);
    const geos: GeoLodItem[] = [];
    console.log(resp.data);
    if (resp.data && resp.data.length > 0) {
      resp.data.forEach((feature: any) => {
        console.log(feature);
        if (feature.geometry && feature.geometry.type == 'Point') {
          const geo: GeoLodItem = {
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1],
            body: feature.properties.title,
            source: 'source',
            id: 0,
            address: feature.properties.title
          };
          geos.push(geo);
        }
      });
      this.errors = [];
    } else {
      this.errors = ['地名の候補がみつかりません'];
      return;
    }
    this.removeMarkers();
    geos.forEach((geo: GeoLodItem) => {
      const marker = this.createMarker(geo);
      if (marker) {
        this.markers.push(marker);
        geo.marker = marker;
      }
    });
    this.fitToMarkers(this.markers);
  }

  async search() {
    if (!this.currentUser) return;
    const url = `https://geolod.ex.nii.ac.jp/api/geo?keywords=${this.query}&dictionary_also=true&limit=10000&offset=-10000`;
    const resp = await axios.get(url);
    const geos: GeoLodItem[] = [];
    if (resp.data.geos && resp.data.geos.length > 0) {
      resp.data.geos.forEach((g: any) => {
        geos.push({
          latitude: g.latitude,
          longitude: g.longitude,
          id: g.id,
          source: g.source,
          body: g.body,
          address: g.address || ''
        });
      });
      this.errors = [];
    } else {
      this.errors = ['地名の候補がみつかりません'];
      return;
    }
    this.removeMarkers();
    geos.forEach((geo: GeoLodItem) => {
      const marker = this.createMarker(geo);
      if (marker) {
        this.markers.push(marker);
        geo.marker = marker;
      }
    });
    this.fitToMarkers(this.markers);
  }

  fitToMarkers(markers: L.Marker[]) {
    if (markers.length == 0) return;
    const latLng = markers.map((m) => m.getLatLng());
    const lats = latLng.map((l) => l.lat);
    const lngs = latLng.map((l) => l.lng);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLong = Math.min(...lngs);
    const maxLong = Math.max(...lngs);
    this.map.fitBounds([
      [minLat, minLong],
      [maxLat, maxLong]
    ]);
  }

  confirmSetLocation() {
    requireLogin();
    this.confirmDialog = true;
  }

  cancel() {
    this.comment = '';
    this.confirmDialog = false;
  }

  async unsetLocation() {
    requireLogin();
    const currentUser = authModule.currentUser;
    if (!currentUser) return;
    const id = this.$route.params.id;
    const data: Location = {
      segId: id,
      editedBy: currentUser.uid,
      comment: '',
      hasGeoInfo: false,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    await db.collection('geo').doc(id).set(data);
    this.drawLocation();
    snackbarModule.SET_TEXT({ text: '位置情報を解除しました' });
    this.comment = '';
  }

  async setLocation() {
    const currentUser = authModule.currentUser;
    if (!currentUser) return;
    const id = this.$route.params.id;
    const center = this.map.getCenter();
    let base64 = (await this.screenshooter.takeScreen('image')) as any;
    base64 = base64.substring(22);
    const data: Location = {
      segId: id,
      lat: center.lat,
      lng: center.lng,
      editedBy: currentUser.uid,
      comment: this.comment,
      hasGeoInfo: true,
      screenshot: firebase.firestore.Blob.fromBase64String(base64),
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    this.confirmDialog = false;
    await db.collection('geo').doc(id).set(data);
    this.drawLocation();
    snackbarModule.SET_TEXT({ text: '位置情報を更新しました' });
    this.comment = '';
    annotationModule.setWatch({ value: true });
  }

  @Watch('$route.params.id')
  async drawLocation() {
    this.removeMarkers();
    if (this.location && this.location.hasGeoInfo) {
      const latlng = this.location as L.LatLngExpression;
      const user = await getUserData(this.location.editedBy);
      this.map.panTo(latlng);
      const marker = L.marker(latlng).addTo(this.map);
      this.markers.push(marker);
      const template = `
        <b>現在設定されている位置</b>
        <p><img height="20" style="margin-right: 3px;" src="${user.photoURL}"><b>${user.displayName}</b>さんによるコメント：</p>
        <p>${this.location.comment}</p>
      `;
      marker.bindPopup(template);
    }
  }

  async bindLocation() {
    const id = this.$route.params.id;
    await this.$bind('location', db.collection('geo').doc(id));
  }

  async mounted() {
    this.map = L.map('geolod-map', {
      center: L.latLng(35.6825, 139.752778),
      zoom: 15
    }).addLayer(
      L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', {
        attribution:
          "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
      })
    );
    const control: any = L.control;
    control
      .centerCross({
        show: true,
        position: 'topleft'
      })
      .addTo(this.map);
    this.screenshooter = new SimpleMapScreenshoter().addTo(this.map);
    await this.bindLocation();
    await this.drawLocation();
  }
}
