















































































import { Location, User, History, Segment } from '@/@types/global';
import { getSegData, getUserData } from '@/utils/firebase';
import dateFormat from 'dateformat';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as firebase from 'firebase/app';
import { historyTypeLabel } from '@/utils/utils';
import HistoryMedia from '../history/HistoryMedia.vue';
import OpenableUserName from '../molecules/OpenableUserName.vue';
import LikeBtn from '../molecules/LikeBtn.vue';
import LikeCounter from '../molecules/LikeCounter.vue';
import PhotoCard from '@/components/reader/PhotoCard.vue';
import { authModule } from '@/store';
@Component({
  components: {
    HistoryMedia,
    OpenableUserName,
    LikeBtn,
    LikeCounter,
    PhotoCard
  }
})
export default class HistoryCard extends Vue {
  @Prop(Object) history!: History;
  user: User | null = null;
  seg: Segment | null = null;

  get historyLabel() {
    return historyTypeLabel(this.history);
  }

  blob2base64(geo: Location) {
    if (!geo.hasGeoInfo) return;
    const base64 = geo.screenshot!.toBase64();
    return 'data:image/png;base64,' + base64;
  }

  get thumbnail() {
    const index = this.seg?.index;
    return `/thumbnails/${index}.jpg`;
  }

  get photoTitle() {
    return `${this.seg!.index} ${this.seg!.metadata[4]}`;
  }

  get dateStr() {
    const t = this.history.timestamp as firebase.default.firestore.Timestamp;
    const date = t.toDate();
    return dateFormat(date, 'yyyy/mm/dd h:MM');
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  async created() {
    const uid = this.history.uid;
    this.user = await getUserData(uid);
    this.seg = await getSegData(this.history.segId);
  }
}
