import firebase from 'firebase/app';
import { ActionContext } from 'vuex';
import { bindCollection, bindDocument } from '@posva/vuefire-core';
export const VUEXFIRE_SET_VALUE = 'vuexfire/SET_VALUE';
export const VUEXFIRE_ARRAY_ADD = 'vuexfire/ARRAY_ADD';
export const VUEXFIRE_ARRAY_REMOVE = 'vuexfire/ARRAY_REMOVE';

const subscriptions = new WeakMap();

function unbind(context: any) {
	const { commit, key } = context;
	const sub = subscriptions.get(commit);
	if (!sub) return;
	sub[key](); // to unsubscribe document
	delete sub[key];
}

function bind(context: any): Promise<any> {
	//console.log("bind", context)
	const { state, commit, key, ref, ops } = context;
	let sub = subscriptions.get(commit);
	if (!sub) {
		sub = Object.create(null);
		subscriptions.set(commit, sub);
	}

	// unbind if ref is already bound
	if (key in sub) {
		unbind({ commit, key });
	}

	return new Promise((resolve, reject) => {
		sub[key] = ref.where
			? bindCollection(
				{
					vm: state,
					key,
					collection: ref,
					ops,
					resolve,
					reject
				},
				{ maxRefDepth: 2 }
			)
			: bindDocument(
				{
					vm: state,
					key,
					document: ref,
					ops,
					resolve,
					reject
				},
				{ maxRefDepth: 2 }
			);
	});
}

export function unbindFirebaseRef(commit: any, key: string) {
	unbind({ commit, key });
}

export function bindFirebaseRef(
	context: ActionContext<ThisType<any>, any>,
	key: string,
	ref: firebase.firestore.DocumentReference | firebase.firestore.CollectionReference | firebase.firestore.Query
): Promise<any> {
	const { state, commit } = context;
	const ops = {
		set: (target: any, path: any, data: any) => {
			commit(
				VUEXFIRE_SET_VALUE,
				{
					path,
					target,
					data
				},
				{ root: true }
			);
			return data;
		},
		add: (target: any, newIndex: number, data: any) =>
			commit(VUEXFIRE_ARRAY_ADD, { target, newIndex, data }, { root: true }),
		remove: (target: any, oldIndex: number) => {
			const data = target[oldIndex];
			commit(VUEXFIRE_ARRAY_REMOVE, { target, oldIndex }, { root: true });
			return [data];
		}
	};
	return bind({ state, commit, key, ref, ops });
}
