




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { History, Segment, User } from '@/@types/global';
import { db, getUserData } from '@/utils/firebase';
import { default as dateFormat } from 'dateformat';
import * as firebase from 'firebase/app';
import HistoryMedia from '../history/HistoryMedia.vue';
import { annotationType2CollectionName, historyTypeLabel } from '@/utils/utils';
import LikeBtn from '../molecules/LikeBtn.vue';
import LikeCounter from '../molecules/LikeCounter.vue';
import { authModule, snackbarModule } from '@/store';
import PhotoCard from '../reader/PhotoCard.vue';
@Component({
  components: { HistoryMedia, LikeBtn, LikeCounter, PhotoCard }
})
export default class HistoryEntry extends Vue {
  @Prop(Object) history!: History;
  @Prop({ default: false, type: Boolean }) showPhoto!: boolean;
  user: User | null = null;
  seg: Segment | null = null;
  editingMode = false;
  newComment = '';

  get hasMedia() {
    return this.history.type !== 'post' && this.history.type !== 'segStatus';
  }

  get historyType() {
    return historyTypeLabel(this.history);
  }

  get dateStr() {
    const t = this.history.timestamp as firebase.default.firestore.Timestamp;
    const date = t.toDate();
    return dateFormat(date, 'yyyy/mm/dd HH:MM');
  }

  get currentUser() {
    return authModule.currentUser;
  }

  get isMyHistory() {
    return this.currentUser && this.currentUser.uid == this.history.uid;
  }

  startEditingComment() {
    this.newComment = this.history.comment;
    this.editingMode = true;
  }

  cancelEditingComment() {
    this.editingMode = false;
  }

  async updateComment() {
    this.editingMode = false;
    if (this.isMyHistory) {
      const batch = db.batch();
      const historyRef = db.collection('histories').doc(this.history.id);
      batch.update(historyRef, {
        comment: this.newComment,
        commentEdited: true,
        oldComment: this.history.comment
      });
      const collectionName = annotationType2CollectionName(this.history.type);
      const annoRef = db.collection(collectionName).doc(this.history.annoId);
      batch.update(annoRef, {
        comment: this.newComment,
        commentEdited: true,
        oldComment: this.history.comment
      });
      await batch.commit();
      snackbarModule.SET_TEXT({ text: 'コメントを更新しました' });
    }
  }

  async created() {
    const uid = this.history.uid;
    this.user = await getUserData(uid);
    const doc = await db.collection('segs').doc(this.history.segId).get();
    this.seg = doc.data() as Segment;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }
}
