




















import Vue from 'vue';
import Component from 'vue-class-component';
import { dialogModule, authModule } from '@/store';

@Component({
  props: { value: Boolean },
  computed: {
    open: {
      get() {
        return dialogModule.isSignInDialogOpen;
      },
      set(isOpen) {
        dialogModule.openSignInDialog({ open: isOpen });
      }
    }
  }
})
export default class SignInDialog extends Vue {
  async signInWithGoogle() {
    this.$emit('close');
    await authModule.signInWithGoogle();
  }

  async signInWithTwitter() {
    this.$emit('close');
    await authModule.signInWithTwitter();
  }

  async signInWithFacebook() {
    this.$emit('close');
    await authModule.signInWithFacebook();
  }
}
