







import { Location } from '@/@types/global';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class GeoHistoryMedia extends Vue {
  @Prop(Object) location!: Location;

  get blob2base64() {
    if (this.location.hasGeoInfo) {
      const base64 = this.location.screenshot!.toBase64();
      return 'data:image/png;base64,' + base64;
    } else {
      throw 'no screenshot data';
    }
  }
}
