






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { db } from '@/utils/firebase';
import { Annotorious, IAnnotation } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';

interface SourceImage {
  id: string;
  uri: string;
  label: string;
  count: number;
  completed: boolean;
}

@Component
export default class Segmentation extends Vue {
  currentImage: SourceImage | null = null;
  images: SourceImage[] = [];
  id!: string;
  annotator!: Annotorious;
  segs: IAnnotation[] = [];
  selectedSeg: IAnnotation | null = null;

  label(image: SourceImage): string {
    return `${image.label}（写真：${image.count}，進捗：${
      image.completed ? '完了' : '未完了'
    }）`;
  }

  get completedImageCount(): number {
    return this.images.filter((i) => i.completed).length;
  }

  setupAnnotator(): void {
    this.annotator = new Annotorious({
      image: 'source-image'
    });
    this.annotator.on('createAnnotation', (anno: IAnnotation) => {
      this.setSegment(anno);
    });
    this.annotator.on('deleteAnnotation', (anno: IAnnotation) => {
      this.deleteSegment(anno);
    });
    this.annotator.on('updateAnnotation', (anno: IAnnotation) => {
      this.setSegment(anno);
    });
    this.annotator.on('selectAnnotation', (anno: IAnnotation) => {
      this.selectedSeg = anno;
    });
  }

  @Watch('$route.params.id')
  async loadCurrentImage(): Promise<void> {
    this.selectedSeg = null;
    const id = this.$route.params.id;
    await this.$bind('currentImage', db.collection('images').doc(id));
    this.setupAnnotator();
    await this.loadSegs();
  }

  async setSegment(anno: IAnnotation): Promise<void> {
    anno.imageId = this.$route.params.id;
    await db.collection('segs').doc(anno.id).set(anno);
    await this.updateImageCount();
  }

  async deleteSegment(anno: IAnnotation): Promise<void> {
    await db.collection('segs').doc(anno.id).delete();
    await this.updateImageCount();
  }

  async updateImageCount(): Promise<void> {
    const count = this.segs.length;
    const id = this.$route.params.id;
    return await db.collection('images').doc(id).update({ count });
  }

  async updateProgress(): Promise<void> {
    const completed = this.currentImage?.completed;
    const id = this.$route.params.id;
    return await db.collection('images').doc(id).update({ completed });
  }

  async loadSegs(): Promise<void> {
    const id = this.$route.params.id;
    const ref = db.collection('segs').where('imageId', '==', id);
    await this.$bind('segs', ref);
    this.segs.forEach((seg) => {
      this.annotator.addAnnotation(seg);
    });
  }

  get imageURI(): string {
    const id = this.$route.params.id;
    const selector = this.selectedSeg?.target.selector.value;
    const matched = selector?.match(/^xywh=pixel:(.+),(.+),(.+),(.+)$/);
    matched?.shift();
    const xywh = matched?.map((m: string) => parseInt(m)).join(',');
    return `https://eiichi.shibusawa.or.jp/denkishiryo-bekkan/api/images/iiif/${id}/${xywh}/max/0/default.jpg`;
  }

  selectSegment(seg: IAnnotation): void {
    this.annotator.selectAnnotation(seg);
    this.selectedSeg = seg;
  }

  onSelectionChange(id: string): void {
    this.$router.push({ path: `/segmentation/${id}` });
  }

  jumpToNextImage() {
    const id = parseInt(this.$route.params.id) + 1;
    this.$router.push({ path: `/segmentation/${id}` });
  }

  jumpToPrevImage() {
    const id = parseInt(this.$route.params.id) - 1;
    this.$router.push({ path: `/segmentation/${id}` });
  }

  async loadImages() {
    return await this.$bind('images', db.collection('images'));
  }

  async mounted() {
    await this.loadImages();
    await this.loadCurrentImage();
  }
}
